export const DiscoverSpiritOfTheTabuk = {
    id: 2,
    name: "Discover Spirit of the Tabuk  winter package.",
    Duration: "3 days and 2 Nights",
    imageUrl: require("../tabuk/21.jpg"),
    description: `This 3-day tour takes travelers into nature from the day they arrive, with visits to the Aldissa valley, Admire the tumbling cliffs and the magical natural wonders of nature followed by Lion Mountain, and the ledge of Neom. Over the next 2 days, explore the history of Tabuk with a visit to the museum then enjoy a hike and safari in the gorgeous mountains, valleys, canyons, and caves-where famous sites like the Catalina plane wreck await and the sprawling beauty of Neom which have lots to offer in the winter season.
    Surrounded by lush oases and striking rock formations, this package is an adventure for those looking to explore the true beauty of Tabuk.
    `,
    whatToExpect: `Upon arrival you will meet our representative outside the arrival gate airport Tabuk (after collecting your baggage) and then will be transferred to the vehicle and proceed to hotel for check-in, and leave your bags and get back to your vehicle, and relax into your air-conditioned 4x4 SUV car, from the moment your tour starts, your tour leader will give you full attention. Once in the car, your tour leader will talk to you about all the things on the way as well as other aspects of your tour that will interest you.`,
    highlights: [
      "Explore the mountain of Jabal Al Lawz.",
      "Hike to Jabel Al Lawz.",
      "Marvel at the structure of the Split Rock of Moses.",
      "See the 12 Stone Altars in front of Jabal Lawz Mountain.",
      "Petroglyph of Golden Calf.",
      "Explore the Wadi Al Disah valley and the Wadi Tayyeb Al Ism Valley.",
      "Visit Hisma, the Wadi Rum of Saudi Arabia & the Bajdah desert.",
      "Visit some sites that have features reminding the story of Moses.",
      "Visit the Wells of Moses and the impressive Valley of Moses (Tayyeb Al Ism valley).",
      "Explore the archeological site of Madyan, an ancient city quoted in both the Holy Quran and the Holy Bible.",
      "Safari 4X4 dune bashing experience and much more.",
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget Saudi.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    daySchadule: [
      {
        id: 1,
        name: "Day 1:Jabel Al Lawz  & The Land of Midian Tour.",
        description: [
          {
            time: "",
            title: "",
            detail:
              "Midian (Al bad) is the land where Mose’s (PBUH) spent 10 years in voluntary exile before returning and freeing its people from Pharaoh Ramses II.) And the homeland of Jethro (Prophet Shuaib (PBUH) who was Mose's Father-in-Law.",
          },
          {
            time: "8:00 AM",
            title: "",
            detail:
              "Assemble at hotel lobby & picked up from hotel by our guide/driver.",
          },
          {
            time: "8:30 AM",
            title: "Proceed to Jabel Al Lawz (Accompanied by a guide/driver).",
            detail: `Start your day journey through time at  Jabal Safina the ship mountain is a mount that takes its name from the way it appears as a ship from a distance in the middle of the Hesma desert (that includes a record of history with Archeological inscriptions on its rocks) then head to Neom and visit Tayyeb Al-Ism. It is the first place Moses (Mosa) (PBUH) reached when he brought the people out of Egypt through the red sea. It is one of Saudi Arabia’s most stunning natural attractions. Visitors to the valley enjoy one surprise after another. The valley is located on the Gulf of Aqaba, 15 kilometers north of the coastal town of Maqna. Red-coloured mountains and deep canyons surround the Wadi Tayyeb al ism valley. This lush oasis was an ancient pit stop for weary travellers. The valley overlooks the sea and is known for its captivating nature and crystal clear water stream throughout the year. Hike and go for a nature walk inside the valley. Take as many pictures as you can so you can look at them every time and remember the fun time you spent there.


            
              `,
          },

          {
            time: "",
            title: "",
            detail: ` Go to the Golden Calf Petroglyph. When the people saw that Moses was so long to come down from the mountain, the Israelites made Aaron an image of a golden calf. Surprisingly, all the above-mentioned traces are found close to Mt. Jabal Maqla. The huge altar, where supposedly the image of the golden calf was placed, cannot be missed. And on the altar's stone are numerous petroglyphs, including the one depicting the people holding a festival and dancing.`,
          },
          {
            time: "",
            title: "",
            detail: `  See the 12-stone altars in front of Jabal Lawz Mountain and proceed to Split Rock Mountain. Examine the Split Rock. The giant rock split is located in the Northwest of Horeb at Jabal Maqla, where Moses struck the rock, and God miraculously provided water from within.
           `,
          },
          {
            time: "",
            title: "",
            detail: `Make a stop for lunch at 13:00. Then proceed to midian.`,
          },

          {
            time: "14:00 AM",
            title:
              "Mugha’yer Shuaib (Prophet Shuaib) (PBUH) tombs & Cave visit.",
            detail: `Midian (Al bad) is the land where Mose’s (PBUH) spent 10 years in voluntary exile before returning and freeing its people from Pharaoh Ramses II.) And the homeland of Jethro (Prophet Shuaib (PBUH) who was Mose's Father-in-Law. The Mugha’yer Shuaib seems to appear from nowhere in the reddish desert west of Tabuk. Moses (PBUH) lived here a decade under the patronage of the Prophet Shuaib (PBUH), who had been impressed by Moses’ chivalry and offered his daughter’s hand in marriage. Moses eventually returned to Egypt, but it’s easy to imagine that this beautiful place stayed with him. Although this place has some of the most beautiful monumental tombs typical of the architecture of the ancient Nabatean kingdom and the Nabatean tombs of Mugha’ir Shuaib are accessible to tourists.`,
          },
          {
            time: "15:00 PM",
            title: "Mose’s (Musa (as) Water well.",
            detail: `Visit the Moses well at Maqna famous for its wells that witnessed an important episode in the life of prophet Mose’s (PBUH) where it is believed that Moses took care of Jethro's (Prophet Shuaib) daughters and helped them take out water for their cattle and he offered to help and this is the first place where Mose's (Musa) met Zippora, Jethro’s daughter in this place and after married with her. Thus Prophet Mose’s settled in Midian and lived there for ten years with his wife and father-in-law.`,
          },
          {
            time: "15:30 PM",
            title: "12 springs of Prophet Mose’s (Musa (as) at Maqna.",
            detail: `12 Springs of Prophet Moses (as) is a historical landmark located in the valley of Maqna, on the coast of the Gulf of Aqaba in northwest Saudi Arabia. The spring of water bubbled up among the palm trees and tall grass and its flow against gravity in the middle of the desert appears to be nothing less than magical. These springs also pump water into the famous valley of Maqna through the sand holes that are driven by water flowing from the ground.`,
          },
          {
            time: "16:00 PM",
            title: "Wadi Tayyeb Al ism valley (Valley of Moses).",
            detail: `Wadi Tayyeb Al Ism Valley.(The possible location where Moses brought his people out of Egypt.)
              Tayyeb Al-Ism is the first place Mose's (Musa) (PBUH) reached when prophet Mose’s brought the people out of Egypt through the red sea, he crossed the Red Sea (As per researchers) and one of Saudi Arabia’s most stunning natural attractions. Visitors to the valley enjoy one surprise after another. The valley is located on the Gulf of Aqaba, 15 Kilometers north of the coastal town of Maqna. The wadi Tayyeb al ism valley is surrounded by red-colored mountains and deep canyons this lush oasis was an ancient pit stop for weary travelers. The valley overlooks the sea and is known for its captivating nature and crystal clear water stream throughout the year. Hike and nature walks inside the valley and take as many pictures as u can so you can look at them every time and remember the fun time you spent there.
              `,
          },
          {
            time: "",
            title:
              "Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.",
            detail: ` `,
          },
          {
            time: "18:30",
            title: "",
            detail: `Ready for departure to Tabuk.
             `,
          },
          {
            time: "20:00",
            title: "Arrive in Tabuk .",
            detail: `Drop off at the hotels and grab your dinner at the hotel`,
          },
          {
            time: "",
            title: "",
            detail: `Overnight stay at the reserved hotel. `,
          },
        ],
      },
      {
        id: 2,
        name: "Day 2: Aldisah Valley Tour.",
        description: [
          {
            time: "08:00 AM",
            title: "",
            detail:
              "Proceed to Aldissa Valley (Wadi Qaraqir) (Accompanied by a guide/driver).",
          },
          {
            time: "08:30 AM",
            title: "Tabuk Castle.",
            detail: `1st Stop:  Tabuk Castle.`,
          },
          {
            time: "",
            title: "",
            detail:
              "Start you journey through at Tabuk castle. The castle in the center of the town is the oldest, it was built in 1559 during the reign of the Ottoman Empire. Check out the Tabuk Castle, the Hijaz Railway Station and take some photos and get back to your vehicles and proceed to Alshaq.",
          },
          {
            time: "09:00 AM",
            title: "The great Canyon Alshaq (Ledge of Neom).",
            detail: `Al-Shaq. This name means the tear (or rip) which makes sense as here the earth has been literally torn apart by tectonic forces. That is the reason why this place is also known as the Great Canyon of Saudi Arabia, and it’s located in the Tabuk region between Jabal Hesma & Wade Qaraqir close to  Shiqry village. Take a foot walk and check out the ledge of NEOM. An overhang offers a perfect perspective for a souvenir picture. You can also check out the famous lion mountain as a mountain resembles the face of a lion. Finish the experience and get back inside the vehicle.`,
          },
          {
            time: "10:30 AM",
            title: "Heads to Disah Valley.",
            detail: `(Disah is mainly located in the Prince Mohammed bin Salman Natural Reserve) in the Southwest Province of Tabuk.`,
          },
          {
            time: "12:30 PM",
            title: ".",
            detail: `Arrive in Disah Valley (The Valley of palms). Upon arrival, you’ll see exactly why. The luscious valley floor is surrounded by massive sandstone cliffs and pillars and beautiful lush palm trees that are perfect for exploration and followed by traditional lunch at one local farm.
            After lunch get ready for a 4x4 vintage car adventure and take a safari tour inside the valley and you will enjoy the flourishing nature, high mountains and water springs and the captivating beauty of the valley and the amazing feature of the wadi is a water stream that runs through the western part of the canyon towards the city of Disah. Numerous palm trees and bushes and high grass flourishes in between the majestic cliffs of the canyon. Enjoy discovering the old engravings on the walls of Nabatean Gate. Hike and nature walk around the valley and followed by coffee/tea with snacks.
            `,
          },
          {
            time: "17:30 AM",
            title: "",
            detail: `Finish the Wadi tour and watch the sunset experience at Hajalah.`,
          },
          {
            time: "18:00 AM",
            title: "",
            detail: `Drive back to Tabuk.`,
          },
          {
            time: "20:00 AM",
            title: "",
            detail: `Arrive in Tabuk. `,
          },
          {
            time: "",
            title: "",
            detail: `	Drop off at the hotel and grab your dinner.`,
          },
          {
            time: "",
            title: "",
            detail: `Overnight stay at the reserved hotel. `,
          },
        ],
      },
      {
        id: 3,
        name: "Hesma/Bajdah Tour.",
        description: [
          {
            time: "08:00 PM",
            title: "	Wake up & have your breakfast at the hotel.",
            detail: `Assemble at hotel lobby & picked up from hotel by our guide/driver.`,
          },
          {
            time: "08:30 PM",
            title: "	",
            detail: `Proceed to Hesma /Bajdah desert by 4WD vehicles (Accompanied by a guide/driver).`,
          },
          {
            time: "09:30 PM",
            title: "	",
            detail: `Arrive on site.`,
          },

          {
            time: "",
            title: "	",
            detail: `	Visit the famous natural arch mount and get close to taking the pictures. `,
          },
          {
            time: "",
            title: "	",
            detail: `Time to go on a safari experience for dashing the dunes in the desert by 4x4. `,
          },
          {
            time: "14:00 PM",
            title: "	",
            detail: `Time for lunch. (Picnic lunch will serve en site).
            
            `,
          },
          {
            time: "",
            title: "	Visit the Neom caves. (Accompanied by the guide)",
            detail: `
            
            `,
          },
          {
            time: "",
            title: "	",
            detail: `	Free time for pictures & Enjoys Tea/ Fresh Arabic coffee with dates & snacks.
            
            `,
          },
          {
            time: "",
            title: "	",
            detail: `After going on an adventurous ride through the desert exploring the gold sand landscape. Enjoy a magical sunset overlooking the glowing dunes in gold and capture some sunset moments with your lens to cherish the memory forever.
            Time to get ready for departure to Tabuk.
            
            `,
          },
          {
            time: "",
            title: "	",
            detail: ` Time to get ready for departure to Tabuk.
            
            `,
          },
          {
            time: "18:30 PM",
            title: "	",
            detail: `Drive back to Tabuk.
            `,
          },
          {
            time: "20:00 PM",
            title: "	",
            detail: `Arrive in Tabuk.
            `,
          },
          {
            time: "",
            title: "	",
            detail: `Drop off at the airport or hotel.
              `,
          },
          {
            time: "",
            title: "	",
            detail: `Farewell from Airport. 
              `,
          },
          {
            time: "",
            title: "	Note: This activity ends back at the meeting point.",
            detail: `
              `,
          },
          {
            time: "Meeting and Pickup:",
            title: "Pickup details.",
            detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
              `,
          },
        ],
      },
    ],
    images1: [
      { id: 1, imageUrl: require("../tabuk/1.JPG") },
      { id: 2, imageUrl: require("../tabuk/2.JPG") },
      { id: 3, imageUrl: require("../tabuk/3.jpg") },
      { id: 4, imageUrl: require("../tabuk/4.jpg") },
      { id: 5, imageUrl: require("../tabuk/5.JPG") },
      { id: 6, imageUrl: require("../tabuk/6.jpg") },
      { id: 7, imageUrl: require("../tabuk/7.png") },
      { id: 8, imageUrl: require("../tabuk/8.jpg") },
      { id: 9, imageUrl: require("../tabuk/9.jpg") },
      { id: 10, imageUrl: require("../tabuk/10.jpg") },
      { id: 11, imageUrl: require("../tabuk/11.jpg") },
      { id: 12, imageUrl: require("../tabuk/12.jpg") },
      { id: 13, imageUrl: require("../tabuk/13.jpg") },
      { id: 14, imageUrl: require("../tabuk/14.jpg") },
      { id: 15, imageUrl: require("../tabuk/15.jpg") },
    ],
    tourDetailsInfo: {
      duration: "3",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  }