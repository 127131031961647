
export const DiscoverWondersOfTabukSummarPackage = {
    id: 1,
    name: "Discover Wonders of the Tabuk Summer package.",
    Duration: "3 days and 2 Nights",
    imageUrl: require("../tabuk/13.jpg"),
    description: `Embark on a three-day journey to discover the wonders of the Tabuk. Admire the 
    tumbling cliffs and the magical natural wonders of nature, the crystal clear water, the 
    mountainous chains, and overlooking the red sea beauty & the beaches. On the 
    southwest side of the city of Tabuk, the magical natural wonder of Wadi Al Disah 
    valley gives you access to stunning views of mountain terrain and flowing springs. The
    wadi is surrounded by massive sandstone cliffs and pillars and beautiful palm trees in 
    the Northwest of Tabuk, where the Haql city is situated near the head of the Gulf of 
    Aqaba, adjacent to Aqaba across the Jordanian border. The coasts of Egypt, Israel, and
    Jordan are visible from the coast.`,
    whatToExpect: `Upon arrival you will meet our representative outside the arrival gate airport Tabuk 
    (after collecting your baggage) and then will be transferred to the vehicle and proceed
    to hotel for check-in, and leave your bags and get back to your vehicle, and relax into 
    your air-conditioned 4x4 SUV car, from the moment your tour starts, your tour leader 
    will give you full attention. Once in the car, your tour leader will talk to you about all 
    the things on the way as well as other aspects of your tour that will interest you.`,
    highlights: [
      " Learn the history and culture of the city at Tabuk local museum.",
      " Explore the Wadi Al Disah valley and the Wadi Tayyeb Al Ism Valley.",
      " Marvel at the Jabal Safina (Ship Mountain) & great canyon known as the ledge of NEOM at Alshaq.",
      "Visit some sites that have features reminding the story of Moses.",
      "Visit the Wells of Moses and the impressive Valley of Moses (Tayyeb Al Ism valley).",
      " Explore the archeological site of Madyan, an ancient city quoted in both the Holy Quran and the Holy Bible.",
      "Watch the Georgios ‘G’ Shipwreck at south of Haql.",
      "Futuristic city Neom tour.",
      "Median tombs visit.",
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget Saudi.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    daySchadule: [
      {
        id: 1,
        name: "Day 1:Aldisah Valley Tour",
        description: [
          {
            time: "8:00 AM",
            title: "",
            detail:
              "Proceed to Aldissa Valley (Wadi Qaraqir) (Accompanied by a guide/driver).",
          },
          {
            time: "8:30 AM",
            title: "1st Stop:  Tabuk Castle.",
            detail:
              "Start you journey through at Tabuk castle. The castle in the center of the town is the oldest, it was built in 1559 during the reign of the Ottoman Empire. Check out the Tabuk Castle, the Hijaz Railway Station and take some photos and get back to your vehicles and proceed to Alshaq.",
          },
          {
            time: "9:00 AM",
            title: "2nd Stop:  The great Canyon Alshaq (Ledge of Neom).",
            detail:
              "Al-Shaq. This name means the tear (or rip) which makes sense as here the earthhas been literally torn apart by tectonic forces. That is the reason why this place is also known as the Great Canyon of Saudi Arabia, and it’s located in the Tabuk region between Jabal Hesma & Wade Qaraqir close to Shiqry village. Take a foot  walk and check out the ledge of NEOM. An overhang offers a perfect perspective for a souvenir picture. You can also check out the famous lion mountain as a mountain resembles the face of a lion. Finish the experience and get back inside the vehicle.",
          },
          {
            time: "10:30 AM",
            title: "Heads to Disah Valley.",
            detail:
              " (Disah is mainly located in the Prince Mohammed bin Salman Natural Reserve) in the Southwest Province of Tabuk.",
          },
          {
            time: "12:30 PM",
            title: "",
            detail:
              "Arrive in Disah Valley (The Valley of palms). Upon arrival, you’ll see  exactly why. The luscious valley floor is surrounded by massive sandstone cliffs  and pillars and beautiful lush palm trees that are perfect for exploration and  followed by traditional lunch at one local farm.",
          },
          {
            time: "",
            title: "",
            detail:
              "After lunch get ready for a 4x4 vintage car adventure and take a safari tour inside the valley and you will enjoy the flourishing nature, high mountains and   water springs and the captivating beauty of the valley and the amazing feature  of the wadi is a water stream that runs through the western part of the canyon  towards the city of Disah. Numerous palm trees and bushes and high grass flourishes in between the majestic cliffs of the canyon. Enjoy discovering the old engravings on the walls of Nabatean Gate. Hike and nature walk around the valley and followed by coffee/tea with snacks.",
          },
          {
            time: "17:30 PM",
            title: "",
            detail:
              "Finish the Wadi tour and watch the sunset experience at Hajalah.",
          },
          {
            time: "18:00 PM",
            title: "",
            detail: "Drive back to Tabuk.",
          },
          {
            time: "20:00 PM",
            title: "",
            detail: "Arrive in Tabuk.",
          },
          {
            time: "",
            title: "",
            detail: "Drop off at the hotel and grab your dinner.",
          },
          {
            time: "",
            title: "",
            detail: "Overnight stay at the reserved hotel.",
          },
        ],
      },
      {
        id: 2,
        name: "Day 2: NEOM Tour",
        description: [
          {
            time: "08:00 AM",
            title: "Wake up & have your breakfast at the hotel.",
            detail:
              "Assemble at hotel lobby & picked up from hotel by our guide/driver.",
          },
          {
            time: "08:30 AM",
            title: "Proceed to Neom (Accompanied by a guide/driver)",
            detail: "",
          },
          {
            time: "09:00 AM",
            title: "Ist Stop: Safina Rock Mount (Jabal Safina جبل السفينة)",
            detail:
              "Start your day journey through time at  Jabal Safina the ship mountain is a mount that takes its name from the way it appears as a ship from a distance in the middle of the Hesma desert (that includes a record of history with Archeological inscriptions on its rocks).You can get closer to the ship and watch the ship mountain & take some pictures and get back to your vehicle for the next stop.",
          },
          {
            time: "09:25 AM",
            title: "2nd Stop: Tabuk Local Museum.",
            detail:
              "Visit the local museum & learn about the history & culture of the city.",
          },
          {
            time: "11:00 AM",
            title:
              "4th Stop: Mugha’yer Shuaib (Prophet Shuaib) (PBUH) tombs & Cave visit.",
            detail:
              "Midian (Al bad) is the land where Mose’s (PBUH) spent 10 years in voluntary exile before returning and freeing its people from Pharaoh Ramses II.) And the homeland of Jethro (Prophet Shuaib (PBUH) who was Mose's Father-in-Law. The Mugha’yer Shuaib seems to appear from nowhere in the reddish desert west of Tabuk. Moses (PBUH) lived here a decade under the patronage of the Prophet  Shuaib (PBUH), who had been impressed by Moses’ chivalry and offered his daughter’s hand in marriage. Moses eventually returned to Egypt, but it’s easy to imagine that this beautiful place stayed with him. Although this place has some of the most beautiful monumental tombs typical of the architecture of the ancient Nabatean kingdom and the Nabatean tombs of Mugha’ir Shuaib are accessible to tourists.",
          },
          {
            time: "12:00 AM",
            title: "5th Stop: Mose’s (Musa (as) Water well.",
            detail:
              "Visit the Moses well at Maqna famous for its wells that witnessed an important episode in the life of prophet Mose’s (PBUH) where it is believed that Moses took care of Jethro's (Prophet Shuaib) daughters and helped them take out water for their cattle and he offered to help and this is the first place where Mose's (Musa) met Zippora, Jethro’s daughter in this place and after married with her. Thus Prophet Mose’s settled in Midian and lived there for ten years with his wife and father-in-law.",
          },
          {
            time: "13:00 PM",
            title:
              "6th Stop: 12 springs of Prophet Mose’s (Musa (as) at Maqna.",
            detail:
              "12 Springs of Prophet Moses (as) is a historical landmark located in the valley of Maqna, on the coast of the Gulf of Aqaba in northwest Saudi Arabia. The spring of water bubbled up among the palm trees and tall grass and its flow against  gravity in the middle of the desert appears to be nothing less than magical. These springs also pump water into the famous valley of Maqna through the sand holes that are driven by water flowing from the ground.",
          },
          {
            time: "14:00 PM",
            title: "1 Time for lunch. (Serve en route).",
            detail: "",
          },
          {
            time: "14:30 PM",
            title: "7th Stop: Wadi Tayyeb Al ism valley (Valley of Moses).",
            detail:
              "Wadi Tayyeb Al Ism Valley.(The possible location where Moses brought his  people out of Egypt.) Tayyeb Al-Ism is the first place Mose's (Musa) (PBUH) reached when prophet Mose’s brought the people out of Egypt through the red sea, he crossed the Red Sea (As per researchers) and one of Saudi Arabia’s most stunning natural attractions. Visitors to the valley enjoy one surprise after another. The valley is located on the Gulf of Aqaba, 15 Kilometers north of the coastal town of Maqna. The wadi Tayyeb al ism valley is surrounded by red-colored mountains and deep canyons this lush oasis was an ancient pit stop for weary travelers. The valley  overlooks the sea and is known for its captivating nature and crystal clear water stream throughout the year. Hike and nature walks inside the valley and take as many pictures as u can so you can look at them every time and remember the fun time you spent there.",
          },
          {
            time: "16:30 PM",
            title: "8th Stop: Catalina Seaplane Wreckage:",
            detail:
              "The wreckage of a PBY-5A Catalina, an American military seaplane from the 1930s.It has been laid on the beach since 22nd March 1960, when the retired American businessman Thomas Kendall landed in the water near the Ras Al-Sheikh Hameed, Saudi Arabia for a stopover during this trip around the world with his children and his secretary. They spent the night there but the next  afternoon they were attacked with machine guns and automatic firearms by Bedouins who believed it was an actual military attack! Mr. Kendall tried to start the Catalina but only succeeded to move it over about a kilometer where it ran  aground on a coral reef. Catalina which was abandoned on the beach for many  years has now become an important touristic spot.",
          },
          {
            time: "",
            title:
              "Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.",
            detail: "",
          },
          {
            time: "18:30 PM",
            title: "Ready for departure and drive to Haql.",
            detail: "",
          },
          {
            time: "20:00 PM",
            title: "Arrive in Haql.",
            detail: "",
          },
          {
            time: "",
            title: "Drop off at the hotels and grab your dinner at the hotel.",
            detail: "",
          },
          {
            time: "",
            title: "Overnight stay at the reserved hotel",
            detail: "",
          },
        ],
      },
      {
        id: 3,
        name: "Day 3: Haql",
        description: [
          {
            time: "",
            title: "",
            detail:
              "Haql is a city in the Northwest of Tabuk near the head of the Gulf of Aqaba, adjacent to Aqaba across the Jordanian border. The coasts of Egypt and Jordan are clearly  visible from the Haql",
          },
          {
            time: "",
            title: "Morning breakfast at the hotel",
            detail: "",
          },
          {
            time: "",
            title:
              "Assemble at hotel lobby & picked up from hotel by our guide/driver.",
            detail: "",
          },
          {
            time: "8:00 AM",
            title: "Proceed to Haql Park.  (Accompanied by a guide/driver).",
            detail: "First stop: AlNakheel Park (Located in the main city)",
          },
          {
            time: "",
            title: "",
            detail:
              "Start your tour by visiting the local park and exploring the park and watching the view of the Aqaba gulf coast. Egypt and Jordan are clearly visible from the  Park.",
          },
          {
            time: "11:30 AM",
            title: "",
            detail:
              "Drive to the red sea crossing. Tour the crossing site area including a possible ride on a boat out onto the Gulf of Aqaba (The red sea).Snorkel or Scuba dive or swim at the red sea crossing from the shore around the vibran coral reefs with clear crystal water. (Gears on Demand).",
          },
          {
            time: "14:00 PM",
            title: "Grab your lunch at the seaside. (Seafood for lunch).",
            detail: "",
          },
          {
            time: "15:00 PM",
            title: "",
            detail:
              " Heads to the south of the Haql city and watch the famous Saudi titanic “Georgios G Shipwreckage”.The wreckage of the ship, dubbed by some Saudis as the ‘Saudi Titanic,’ is one of the main tourist attractions in Haql. ‘Georgios G’ was built in England after the end of the Second World War, and in 1958 was launched as a cargo liner owned by several individuals and companies.in 1978, when the vessel became stranded on a reef off the Saudi coast in the Gulf of Aqaba, Now an important part of NEOM’s marine conservation.",
          },
          {
            time: "17:00 PM",
            title: "7th Stop: Wadi Tayyeb Al ism valley (Valley of Moses).",
            detail:
              "Wadi Tayyeb Al Ism Valley.(The possible location where Moses brought his  people out of Egypt.) Tayyeb Al-Ism is the first place Mose's (Musa) (PBUH) reached when prophet Mose’s brought the people out of Egypt through the red sea, he crossed the Red Sea (As per researchers) and one of Saudi Arabia’s most stunning natural attractions. Visitors to the valley enjoy one surprise after another. The valley is located on the Gulf of Aqaba, 15 Kilometers north of the coastal town of Maqna. The wadi Tayyeb al ism valley is surrounded by red-colored mountains and deep canyons this lush oasis was an ancient pit stop for weary travelers. The valley  overlooks the sea and is known for its captivating nature and crystal clear water stream throughout the year. Hike and nature walks inside the valley and take as many pictures as u can so you can look at them every time and remember the fun time you spent there.",
          },
          {
            time: "17:00 PM",
            title: "",
            detail: `Free time for pictures Enjoy Tea/ coffee with Arabic dates with snacks at the beach.
              Enjoy a magical sunset overlooking the sea and capture some sunset moments with your lens to cherish the memory forever.
              `,
          },
          {
            time: "18:00 PM",
            title: " Get back into vehicles and drive to Tabuk",
            detail: "",
          },

          {
            time: "20:00 PM",
            title: "Arrive in Tabuk.",
            detail: "Drop off at the airport.",
          },
          {
            time: "",
            title: "",
            detail: "Farewell from the Tabuk airport",
          },
          {
            time: "",
            title: "Note: This activity ends back at the meeting point.",
            detail: "",
          },
          {
            time: "Meeting And Pickup",
            title: "Pickup details.",
            detail:
              "We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.",
          },
        ],
      },
    ],
    images1: [
      { id: 1, imageUrl: require("../tabuk/tabukSummer/1.JPG") },
      { id: 2, imageUrl: require("../tabuk/tabukSummer/2.JPG") },
      
      { id: 4, imageUrl: require("../tabuk/tabukSummer/4.JPG") },
    
      
      
      { id: 8, imageUrl: require("../tabuk/tabukSummer/8.jpg") },
      { id: 9, imageUrl: require("../tabuk/tabukSummer/9.jpg") },
      { id: 10, imageUrl: require("../tabuk/tabukSummer/10.jpg") },
      { id: 11, imageUrl: require("../tabuk/tabukSummer/11.jpg") },
      
     
      { id: 15, imageUrl: require("../tabuk/tabukSummer/15.JPG") },
      { id: 16, imageUrl: require("../tabuk/tabukSummer/16.jpg") },
     
      { id: 18, imageUrl: require("../tabuk/tabukSummer/18.jpg") },
      { id: 19, imageUrl: require("../tabuk/tabukSummer/19.jpg") },
      { id: 20, imageUrl: require("../tabuk/tabukSummer/20.jpg") },
      { id: 21, imageUrl: require("../tabuk/tabukSummer/21.jpg") },
      { id: 22, imageUrl: require("../tabuk/tabukSummer/22.jpg") },
      { id: 23, imageUrl: require("../tabuk/tabukSummer/23.jpg") },
      { id: 24, imageUrl: require("../tabuk/tabukSummer/24.jpg") },
      { id: 25, imageUrl: require("../tabuk/tabukSummer/25.jpg") },
      
      { id: 28, imageUrl: require("../tabuk/tabukSummer/28.jpg") },
      { id: 29, imageUrl: require("../tabuk/tabukSummer/29.jpg") },
      { id: 30, imageUrl: require("../tabuk/tabukSummer/30.jpg") },
      { id: 31, imageUrl: require("../tabuk/tabukSummer/31.jpg") },
      { id: 32, imageUrl: require("../tabuk/tabukSummer/32.jpg") },
      { id: 33, imageUrl: require("../tabuk/tabukSummer/33.jpg") },
      { id: 34, imageUrl: require("../tabuk/tabukSummer/34.jpg") },
      { id: 35, imageUrl: require("../tabuk/tabukSummer/35.jpg") },
      { id: 36, imageUrl: require("../tabuk/tabukSummer/36.jpg") },
      { id: 37, imageUrl: require("../tabuk/tabukSummer/37.jpg") },
      { id: 38, imageUrl: require("../tabuk/tabukSummer/38.jpg") },
      { id: 39, imageUrl: require("../tabuk/tabukSummer/39.jpg") },
      { id: 40, imageUrl: require("../tabuk/tabukSummer/40.jpg") },
      { id: 41, imageUrl: require("../tabuk/tabukSummer/41.jpeg") },
      { id: 42, imageUrl: require("../tabuk/tabukSummer/42.jpg") },
      { id: 43, imageUrl: require("../tabuk/tabukSummer/43.jpg") },
      { id: 44, imageUrl: require("../tabuk/tabukSummer/44.jpg") },
      { id: 45, imageUrl: require("../tabuk/tabukSummer/45.jpg") },
      { id: 46, imageUrl: require("../tabuk/tabukSummer/46.jpg") },
      { id: 47, imageUrl: require("../tabuk/tabukSummer/47.jpg") },
     
      
    ],

    tourDetailsInfo: {
      duration: "3",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  }