import { DiscoverWondersOfTabukSummarPackage } from "./DiscoverWondersOfTabukSummarPackage";
import { DiscoverSpiritOfTheTabuk } from "./DiscoverSpiritOfTheTabuk";

export const tourData = [

  {
    id: 'discover-wonders-of-the-gulmarg-winter-package',
    name: "Discover Wonders of the GULMARG Winter package",
    // Duration: "3 days and 2 Nights",
    imageUrl: require("../tabuk/gulmarg.jpg"),
    description: `
    Kashmir, Gulmarg, is transformed into a winter paradise with stunning scenery covered with fresh snow. With activities ranging from exhilarating ski experiences on well-known slopes to tranquil gondola rides that offer breathtaking panoramic views of snow-capped mountains, this package is meant to fully immerse you in the enchanted atmosphere of Gulmarg. Accept the allure of this charming town, its friendly people, and the plethora of things to do that make it the ideal winter retreat. Come along with us to make priceless memories in the winter wonderland of Gulmarg.`,
    whatToExpect: `Upon your arrival, our representative will warmly greet you outside the Srinagar airport's arrival gate (once you collect your baggage). You'll then be transferred to the hotel for check-in. After leaving your bags, settle into your comfortable air-conditioned SUV. Throughout the journey, your attentive tour leader will provide insights into the scenic surroundings, ensuring an informative and enjoyable experience. As you embark on your tour, let the captivating tales of Kashmir unfold, enhancing your exploration of this mesmerizing region.`,
    highlights: [
      
      " Scenic Gondola Ride: Experience one of the world's highest cable car rides, offering unparalleled views of Gulmarg's winter landscapes.",
      "Skiing and Snowboarding:Glide down some of the best slopes in Asia, suitable for both beginners and advanced enthusiasts.",
      "Snowshoeing Adventures: Explore the serene beauty of snow-covered meadows and forests on a guided snowshoeing tour.",
      "Historic Shrine of Baba Reshi: Visit the sacred shrine dedicated to Baba Reshi, a revered saint, set amidst a stunning snowy backdrop.",
      "Ice Skating and Tobogganing: Enjoy the thrill of ice skating or the joy of tobogganing in the snow-clad regions of Gulmarg.",
      "Photography Tour: Capture the splendid winter beauty of Gulmarg, from its majestic mountains to its quaint village life.",
      "Local Cuisine Tasting:Savor the flavors of Kashmiri dishes that warm the heart and soul, enhancing your winter experience.",
      "Luxurious Winter Stays: Relax in cozy, comfortable accommodations that offer breathtaking views and warm Kashmiri hospitality.",
      "Cultural Experiences: Immerse yourself in the rich culture of Kashmir through local music, dance, and craftsmanship.",
      "Customized Adventure Sports:Tailor your adventure with options for heli-skiing, ice climbing, and more for the adrenaline seekers."
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [   
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Aldisah Valley Tour",
    //     description: [
    //       {
    //         time: "8:00 AM",
    //         title: "",
    //         detail:
    //           "Proceed to Aldissa Valley (Wadi Qaraqir) (Accompanied by a guide/driver).",
    //       },
    //       {
    //         time: "8:30 AM",
    //         title: "1st Stop:  Tabuk Castle.",
    //         detail:
    //           "Start you journey through at Tabuk castle. The castle in the center of the town is the oldest, it was built in 1559 during the reign of the Ottoman Empire. Check out the Tabuk Castle, the Hijaz Railway Station and take some photos and get back to your vehicles and proceed to Alshaq.",
    //       },
    //       {
    //         time: "9:00 AM",
    //         title: "2nd Stop:  The great Canyon Alshaq (Ledge of Neom).",
    //         detail:
    //           "Al-Shaq. This name means the tear (or rip) which makes sense as here the earthhas been literally torn apart by tectonic forces. That is the reason why this place is also known as the Great Canyon of Saudi Arabia, and it’s located in the Tabuk region between Jabal Hesma & Wade Qaraqir close to Shiqry village. Take a foot  walk and check out the ledge of NEOM. An overhang offers a perfect perspective for a souvenir picture. You can also check out the famous lion mountain as a mountain resembles the face of a lion. Finish the experience and get back inside the vehicle.",
    //       },
    //       {
    //         time: "10:30 AM",
    //         title: "Heads to Disah Valley.",
    //         detail:
    //           " (Disah is mainly located in the Prince Mohammed bin Salman Natural Reserve) in the Southwest Province of Tabuk.",
    //       },
    //       {
    //         time: "12:30 PM",
    //         title: "",
    //         detail:
    //           "Arrive in Disah Valley (The Valley of palms). Upon arrival, you’ll see  exactly why. The luscious valley floor is surrounded by massive sandstone cliffs  and pillars and beautiful lush palm trees that are perfect for exploration and  followed by traditional lunch at one local farm.",
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail:
    //           "After lunch get ready for a 4x4 vintage car adventure and take a safari tour inside the valley and you will enjoy the flourishing nature, high mountains and   water springs and the captivating beauty of the valley and the amazing feature  of the wadi is a water stream that runs through the western part of the canyon  towards the city of Disah. Numerous palm trees and bushes and high grass flourishes in between the majestic cliffs of the canyon. Enjoy discovering the old engravings on the walls of Nabatean Gate. Hike and nature walk around the valley and followed by coffee/tea with snacks.",
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail:
    //           "Finish the Wadi tour and watch the sunset experience at Hajalah.",
    //       },
    //       {
    //         time: "18:00 PM",
    //         title: "",
    //         detail: "Drive back to Tabuk.",
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "",
    //         detail: "Arrive in Tabuk.",
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: "Drop off at the hotel and grab your dinner.",
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: "Overnight stay at the reserved hotel.",
    //       },
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: NEOM Tour",
    //     description: [
    //       {
    //         time: "08:00 AM",
    //         title: "Wake up & have your breakfast at the hotel.",
    //         detail:
    //           "Assemble at hotel lobby & picked up from hotel by our guide/driver.",
    //       },
    //       {
    //         time: "08:30 AM",
    //         title: "Proceed to Neom (Accompanied by a guide/driver)",
    //         detail: "",
    //       },
    //       {
    //         time: "09:00 AM",
    //         title: "Ist Stop: Safina Rock Mount (Jabal Safina جبل السفينة)",
    //         detail:
    //           "Start your day journey through time at  Jabal Safina the ship mountain is a mount that takes its name from the way it appears as a ship from a distance in the middle of the Hesma desert (that includes a record of history with Archeological inscriptions on its rocks).You can get closer to the ship and watch the ship mountain & take some pictures and get back to your vehicle for the next stop.",
    //       },
    //       {
    //         time: "09:25 AM",
    //         title: "2nd Stop: Tabuk Local Museum.",
    //         detail:
    //           "Visit the local museum & learn about the history & culture of the city.",
    //       },
    //       {
    //         time: "11:00 AM",
    //         title:
    //           "4th Stop: Mugha’yer Shuaib (Prophet Shuaib) (PBUH) tombs & Cave visit.",
    //         detail:
    //           "Midian (Al bad) is the land where Mose’s (PBUH) spent 10 years in voluntary exile before returning and freeing its people from Pharaoh Ramses II.) And the homeland of Jethro (Prophet Shuaib (PBUH) who was Mose's Father-in-Law. The Mugha’yer Shuaib seems to appear from nowhere in the reddish desert west of Tabuk. Moses (PBUH) lived here a decade under the patronage of the Prophet  Shuaib (PBUH), who had been impressed by Moses’ chivalry and offered his daughter’s hand in marriage. Moses eventually returned to Egypt, but it’s easy to imagine that this beautiful place stayed with him. Although this place has some of the most beautiful monumental tombs typical of the architecture of the ancient Nabatean kingdom and the Nabatean tombs of Mugha’ir Shuaib are accessible to tourists.",
    //       },
    //       {
    //         time: "12:00 AM",
    //         title: "5th Stop: Mose’s (Musa (as) Water well.",
    //         detail:
    //           "Visit the Moses well at Maqna famous for its wells that witnessed an important episode in the life of prophet Mose’s (PBUH) where it is believed that Moses took care of Jethro's (Prophet Shuaib) daughters and helped them take out water for their cattle and he offered to help and this is the first place where Mose's (Musa) met Zippora, Jethro’s daughter in this place and after married with her. Thus Prophet Mose’s settled in Midian and lived there for ten years with his wife and father-in-law.",
    //       },
    //       {
    //         time: "13:00 PM",
    //         title:
    //           "6th Stop: 12 springs of Prophet Mose’s (Musa (as) at Maqna.",
    //         detail:
    //           "12 Springs of Prophet Moses (as) is a historical landmark located in the valley of Maqna, on the coast of the Gulf of Aqaba in northwest Saudi Arabia. The spring of water bubbled up among the palm trees and tall grass and its flow against  gravity in the middle of the desert appears to be nothing less than magical. These springs also pump water into the famous valley of Maqna through the sand holes that are driven by water flowing from the ground.",
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "1 Time for lunch. (Serve en route).",
    //         detail: "",
    //       },
    //       {
    //         time: "14:30 PM",
    //         title: "7th Stop: Wadi Tayyeb Al ism valley (Valley of Moses).",
    //         detail:
    //           "Wadi Tayyeb Al Ism Valley.(The possible location where Moses brought his  people out of Egypt.) Tayyeb Al-Ism is the first place Mose's (Musa) (PBUH) reached when prophet Mose’s brought the people out of Egypt through the red sea, he crossed the Red Sea (As per researchers) and one of Saudi Arabia’s most stunning natural attractions. Visitors to the valley enjoy one surprise after another. The valley is located on the Gulf of Aqaba, 15 Kilometers north of the coastal town of Maqna. The wadi Tayyeb al ism valley is surrounded by red-colored mountains and deep canyons this lush oasis was an ancient pit stop for weary travelers. The valley  overlooks the sea and is known for its captivating nature and crystal clear water stream throughout the year. Hike and nature walks inside the valley and take as many pictures as u can so you can look at them every time and remember the fun time you spent there.",
    //       },
    //       {
    //         time: "16:30 PM",
    //         title: "8th Stop: Catalina Seaplane Wreckage:",
    //         detail:
    //           "The wreckage of a PBY-5A Catalina, an American military seaplane from the 1930s.It has been laid on the beach since 22nd March 1960, when the retired American businessman Thomas Kendall landed in the water near the Ras Al-Sheikh Hameed, Saudi Arabia for a stopover during this trip around the world with his children and his secretary. They spent the night there but the next  afternoon they were attacked with machine guns and automatic firearms by Bedouins who believed it was an actual military attack! Mr. Kendall tried to start the Catalina but only succeeded to move it over about a kilometer where it ran  aground on a coral reef. Catalina which was abandoned on the beach for many  years has now become an important touristic spot.",
    //       },
    //       {
    //         time: "",
    //         title:
    //           "Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.",
    //         detail: "",
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "Ready for departure and drive to Haql.",
    //         detail: "",
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "Arrive in Haql.",
    //         detail: "",
    //       },
    //       {
    //         time: "",
    //         title: "Drop off at the hotels and grab your dinner at the hotel.",
    //         detail: "",
    //       },
    //       {
    //         time: "",
    //         title: "Overnight stay at the reserved hotel",
    //         detail: "",
    //       },
    //     ],
    //   },
    //   {
    //     id: 3,
    //     name: "Day 3: Haql",
    //     description: [
    //       {
    //         time: "",
    //         title: "",
    //         detail:
    //           "Haql is a city in the Northwest of Tabuk near the head of the Gulf of Aqaba, adjacent to Aqaba across the Jordanian border. The coasts of Egypt and Jordan are clearly  visible from the Haql",
    //       },
    //       {
    //         time: "",
    //         title: "Morning breakfast at the hotel",
    //         detail: "",
    //       },
    //       {
    //         time: "",
    //         title:
    //           "Assemble at hotel lobby & picked up from hotel by our guide/driver.",
    //         detail: "",
    //       },
    //       {
    //         time: "8:00 AM",
    //         title: "Proceed to Haql Park.  (Accompanied by a guide/driver).",
    //         detail: "First stop: AlNakheel Park (Located in the main city)",
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail:
    //           "Start your tour by visiting the local park and exploring the park and watching the view of the Aqaba gulf coast. Egypt and Jordan are clearly visible from the  Park.",
    //       },
    //       {
    //         time: "11:30 AM",
    //         title: "",
    //         detail:
    //           "Drive to the red sea crossing. Tour the crossing site area including a possible ride on a boat out onto the Gulf of Aqaba (The red sea).Snorkel or Scuba dive or swim at the red sea crossing from the shore around the vibran coral reefs with clear crystal water. (Gears on Demand).",
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "Grab your lunch at the seaside. (Seafood for lunch).",
    //         detail: "",
    //       },
    //       {
    //         time: "15:00 PM",
    //         title: "",
    //         detail:
    //           " Heads to the south of the Haql city and watch the famous Saudi titanic “Georgios G Shipwreckage”.The wreckage of the ship, dubbed by some Saudis as the ‘Saudi Titanic,’ is one of the main tourist attractions in Haql. ‘Georgios G’ was built in England after the end of the Second World War, and in 1958 was launched as a cargo liner owned by several individuals and companies.in 1978, when the vessel became stranded on a reef off the Saudi coast in the Gulf of Aqaba, Now an important part of NEOM’s marine conservation.",
    //       },
    //       {
    //         time: "17:00 PM",
    //         title: "7th Stop: Wadi Tayyeb Al ism valley (Valley of Moses).",
    //         detail:
    //           "Wadi Tayyeb Al Ism Valley.(The possible location where Moses brought his  people out of Egypt.) Tayyeb Al-Ism is the first place Mose's (Musa) (PBUH) reached when prophet Mose’s brought the people out of Egypt through the red sea, he crossed the Red Sea (As per researchers) and one of Saudi Arabia’s most stunning natural attractions. Visitors to the valley enjoy one surprise after another. The valley is located on the Gulf of Aqaba, 15 Kilometers north of the coastal town of Maqna. The wadi Tayyeb al ism valley is surrounded by red-colored mountains and deep canyons this lush oasis was an ancient pit stop for weary travelers. The valley  overlooks the sea and is known for its captivating nature and crystal clear water stream throughout the year. Hike and nature walks inside the valley and take as many pictures as u can so you can look at them every time and remember the fun time you spent there.",
    //       },
    //       {
    //         time: "17:00 PM",
    //         title: "",
    //         detail: `Free time for pictures Enjoy Tea/ coffee with Arabic dates with snacks at the beach.
    //           Enjoy a magical sunset overlooking the sea and capture some sunset moments with your lens to cherish the memory forever.
    //           `,
    //       },
    //       {
    //         time: "18:00 PM",
    //         title: " Get back into vehicles and drive to Tabuk",
    //         detail: "",
    //       },

    //       {
    //         time: "20:00 PM",
    //         title: "Arrive in Tabuk.",
    //         detail: "Drop off at the airport.",
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: "Farewell from the Tabuk airport",
    //       },
    //       {
    //         time: "",
    //         title: "Note: This activity ends back at the meeting point.",
    //         detail: "",
    //       },
    //       {
    //         time: "Meeting And Pickup",
    //         title: "Pickup details.",
    //         detail:
    //           "We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.",
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../tabuk/tabukSummer/GULMARG/01.jpg") },
      { id: 2, imageUrl: require("../tabuk/tabukSummer/GULMARG/02.jpg") },
      
      { id: 4, imageUrl: require("../tabuk/tabukSummer/GULMARG/03 .webp") },
    
      
      
      // { id: 8, imageUrl: require("../tabuk/tabukSummer/GULMARG/03 .webp") },
      { id: 9, imageUrl: require("../tabuk/tabukSummer/GULMARG/04 .jpg") },
      { id: 10, imageUrl: require("../tabuk/tabukSummer/GULMARG/05.webp") },
      { id: 11, imageUrl: require("../tabuk/tabukSummer/GULMARG/06.jpg") },
      
     
      { id: 15, imageUrl: require("../tabuk/tabukSummer/GULMARG/07.webp") },
      { id: 16, imageUrl: require("../tabuk/tabukSummer/GULMARG/08.jpeg") },
     
      { id: 18, imageUrl: require("../tabuk/tabukSummer/GULMARG/10.webp") },
     
      
    ],

    tourDetailsInfo: {
      duration: "3",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: 'discover-spirit-of-the-pahalgam-winter-package',
    name: "Discover Spirit of the PAHALGAM winter package",
    // Duration: "3 days and 2 Nights",
    imageUrl: require("../tabuk/PAHALGAM-TOURIST-SPOT.jpg"),
    description: `
    With our "Discover Spirit of the Pahalgam Winter Package," set out on an adventure to discover the tranquil beauty of Pahalgam. Pahalgam, a gorgeous hideaway that comes to life in the winter and showcases the splendour of its snow-clad landscapes and frozen rivers, is tucked away in the serene valleys of Jammu and Kashmir. This package has been carefully designed to provide you with a perfect balance of leisure, exploration, and cultural exposure. Pahalgam is a paradise for nature lovers and adventure seekers alike in the winter, offering everything from the thrilling experience of snow sports against the backdrop of the Himalayas to serene treks through valleys covered in pines. Savour the regional food, tour historic temples, and allow Pahalgam's stunning winter scenery to enchant you. Come explore the essence of Pahalgam with us.`,
    whatToExpect: `
    Upon landing in Kashmir, our representative will meet you at Srinagar airport and transfer you to the hotel. After check-in, relax in your air-conditioned 4x4 SUV. Throughout the journey, your attentive tour leader will provide insights and information, ensuring a comfortable and informative exploration of Kashmir's scenic beauty.`,
    highlights: [
    
"Betab Valley Exploration: Wander through the snow-laden landscapes of Betab Valley, famous for its cinematic beauty and serene environment.",
"Aru Valley Adventure:Experience the untouched beauty of Aru Valley, a base for trekking, and enjoy the picturesque vistas of frozen lakes and lush meadows.",
"Lidder River Walks:Take a tranquil walk along the banks of the frozen Lidder River, surrounded by majestic snow-capped peaks.",
"Ice Fishing: Try your hand at ice fishing in the frozen waters, a unique winter activity that offers a peaceful escape.",
"Pahalgam Golf Course Visit: Behold the winter beauty of one of the highest golf courses in the world, covered in a blanket of snow.",
"Kashmiri Cuisine Tasting: Relish the warmth of traditional Kashmiri cuisine, offering a taste of local culture and hospitality.",
"Ancient Temple Tours: Explore the ancient temples around Pahalgam, offering a glimpse into the region's rich cultural and religious heritage.",
"Photography Expeditions: Capture the breathtaking winter scenery of Pahalgam, from its frozen waterfalls to snow-covered pine forests.",
"Local Handicrafts Shopping:Discover the exquisite craftsmanship of Pahalgam, from handwoven shawls to intricately carved wooden artifacts, perfect for souvenirs.",





,
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [   
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Jabel Al Lawz  & The Land of Midian Tour.",
    //     description: [
    //       {
    //         time: "",
    //         title: "",
    //         detail:
    //           "Midian (Al bad) is the land where Mose’s (PBUH) spent 10 years in voluntary exile before returning and freeing its people from Pharaoh Ramses II.) And the homeland of Jethro (Prophet Shuaib (PBUH) who was Mose's Father-in-Law.",
    //       },
    //       {
    //         time: "8:00 AM",
    //         title: "",
    //         detail:
    //           "Assemble at hotel lobby & picked up from hotel by our guide/driver.",
    //       },
    //       {
    //         time: "8:30 AM",
    //         title: "Proceed to Jabel Al Lawz (Accompanied by a guide/driver).",
    //         detail: `Start your day journey through time at  Jabal Safina the ship mountain is a mount that takes its name from the way it appears as a ship from a distance in the middle of the Hesma desert (that includes a record of history with Archeological inscriptions on its rocks) then head to Neom and visit Tayyeb Al-Ism. It is the first place Moses (Mosa) (PBUH) reached when he brought the people out of Egypt through the red sea. It is one of Saudi Arabia’s most stunning natural attractions. Visitors to the valley enjoy one surprise after another. The valley is located on the Gulf of Aqaba, 15 kilometers north of the coastal town of Maqna. Red-coloured mountains and deep canyons surround the Wadi Tayyeb al ism valley. This lush oasis was an ancient pit stop for weary travellers. The valley overlooks the sea and is known for its captivating nature and crystal clear water stream throughout the year. Hike and go for a nature walk inside the valley. Take as many pictures as you can so you can look at them every time and remember the fun time you spent there.


            
    //           `,
    //       },

    //       {
    //         time: "",
    //         title: "",
    //         detail: ` Go to the Golden Calf Petroglyph. When the people saw that Moses was so long to come down from the mountain, the Israelites made Aaron an image of a golden calf. Surprisingly, all the above-mentioned traces are found close to Mt. Jabal Maqla. The huge altar, where supposedly the image of the golden calf was placed, cannot be missed. And on the altar's stone are numerous petroglyphs, including the one depicting the people holding a festival and dancing.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `  See the 12-stone altars in front of Jabal Lawz Mountain and proceed to Split Rock Mountain. Examine the Split Rock. The giant rock split is located in the Northwest of Horeb at Jabal Maqla, where Moses struck the rock, and God miraculously provided water from within.
    //        `,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Make a stop for lunch at 13:00. Then proceed to midian.`,
    //       },

    //       {
    //         time: "14:00 AM",
    //         title:
    //           "Mugha’yer Shuaib (Prophet Shuaib) (PBUH) tombs & Cave visit.",
    //         detail: `Midian (Al bad) is the land where Mose’s (PBUH) spent 10 years in voluntary exile before returning and freeing its people from Pharaoh Ramses II.) And the homeland of Jethro (Prophet Shuaib (PBUH) who was Mose's Father-in-Law. The Mugha’yer Shuaib seems to appear from nowhere in the reddish desert west of Tabuk. Moses (PBUH) lived here a decade under the patronage of the Prophet Shuaib (PBUH), who had been impressed by Moses’ chivalry and offered his daughter’s hand in marriage. Moses eventually returned to Egypt, but it’s easy to imagine that this beautiful place stayed with him. Although this place has some of the most beautiful monumental tombs typical of the architecture of the ancient Nabatean kingdom and the Nabatean tombs of Mugha’ir Shuaib are accessible to tourists.`,
    //       },
    //       {
    //         time: "15:00 PM",
    //         title: "Mose’s (Musa (as) Water well.",
    //         detail: `Visit the Moses well at Maqna famous for its wells that witnessed an important episode in the life of prophet Mose’s (PBUH) where it is believed that Moses took care of Jethro's (Prophet Shuaib) daughters and helped them take out water for their cattle and he offered to help and this is the first place where Mose's (Musa) met Zippora, Jethro’s daughter in this place and after married with her. Thus Prophet Mose’s settled in Midian and lived there for ten years with his wife and father-in-law.`,
    //       },
    //       {
    //         time: "15:30 PM",
    //         title: "12 springs of Prophet Mose’s (Musa (as) at Maqna.",
    //         detail: `12 Springs of Prophet Moses (as) is a historical landmark located in the valley of Maqna, on the coast of the Gulf of Aqaba in northwest Saudi Arabia. The spring of water bubbled up among the palm trees and tall grass and its flow against gravity in the middle of the desert appears to be nothing less than magical. These springs also pump water into the famous valley of Maqna through the sand holes that are driven by water flowing from the ground.`,
    //       },
    //       {
    //         time: "16:00 PM",
    //         title: "Wadi Tayyeb Al ism valley (Valley of Moses).",
    //         detail: `Wadi Tayyeb Al Ism Valley.(The possible location where Moses brought his people out of Egypt.)
    //           Tayyeb Al-Ism is the first place Mose's (Musa) (PBUH) reached when prophet Mose’s brought the people out of Egypt through the red sea, he crossed the Red Sea (As per researchers) and one of Saudi Arabia’s most stunning natural attractions. Visitors to the valley enjoy one surprise after another. The valley is located on the Gulf of Aqaba, 15 Kilometers north of the coastal town of Maqna. The wadi Tayyeb al ism valley is surrounded by red-colored mountains and deep canyons this lush oasis was an ancient pit stop for weary travelers. The valley overlooks the sea and is known for its captivating nature and crystal clear water stream throughout the year. Hike and nature walks inside the valley and take as many pictures as u can so you can look at them every time and remember the fun time you spent there.
    //           `,
    //       },
    //       {
    //         time: "",
    //         title:
    //           "Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.",
    //         detail: ` `,
    //       },
    //       {
    //         time: "18:30",
    //         title: "",
    //         detail: `Ready for departure to Tabuk.
    //          `,
    //       },
    //       {
    //         time: "20:00",
    //         title: "Arrive in Tabuk .",
    //         detail: `Drop off at the hotels and grab your dinner at the hotel`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Overnight stay at the reserved hotel. `,
    //       },
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: Aldisah Valley Tour.",
    //     description: [
    //       {
    //         time: "08:00 AM",
    //         title: "",
    //         detail:
    //           "Proceed to Aldissa Valley (Wadi Qaraqir) (Accompanied by a guide/driver).",
    //       },
    //       {
    //         time: "08:30 AM",
    //         title: "Tabuk Castle.",
    //         detail: `1st Stop:  Tabuk Castle.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail:
    //           "Start you journey through at Tabuk castle. The castle in the center of the town is the oldest, it was built in 1559 during the reign of the Ottoman Empire. Check out the Tabuk Castle, the Hijaz Railway Station and take some photos and get back to your vehicles and proceed to Alshaq.",
    //       },
    //       {
    //         time: "09:00 AM",
    //         title: "The great Canyon Alshaq (Ledge of Neom).",
    //         detail: `Al-Shaq. This name means the tear (or rip) which makes sense as here the earth has been literally torn apart by tectonic forces. That is the reason why this place is also known as the Great Canyon of Saudi Arabia, and it’s located in the Tabuk region between Jabal Hesma & Wade Qaraqir close to  Shiqry village. Take a foot walk and check out the ledge of NEOM. An overhang offers a perfect perspective for a souvenir picture. You can also check out the famous lion mountain as a mountain resembles the face of a lion. Finish the experience and get back inside the vehicle.`,
    //       },
    //       {
    //         time: "10:30 AM",
    //         title: "Heads to Disah Valley.",
    //         detail: `(Disah is mainly located in the Prince Mohammed bin Salman Natural Reserve) in the Southwest Province of Tabuk.`,
    //       },
    //       {
    //         time: "12:30 PM",
    //         title: ".",
    //         detail: `Arrive in Disah Valley (The Valley of palms). Upon arrival, you’ll see exactly why. The luscious valley floor is surrounded by massive sandstone cliffs and pillars and beautiful lush palm trees that are perfect for exploration and followed by traditional lunch at one local farm.
    //         After lunch get ready for a 4x4 vintage car adventure and take a safari tour inside the valley and you will enjoy the flourishing nature, high mountains and water springs and the captivating beauty of the valley and the amazing feature of the wadi is a water stream that runs through the western part of the canyon towards the city of Disah. Numerous palm trees and bushes and high grass flourishes in between the majestic cliffs of the canyon. Enjoy discovering the old engravings on the walls of Nabatean Gate. Hike and nature walk around the valley and followed by coffee/tea with snacks.
    //         `,
    //       },
    //       {
    //         time: "17:30 AM",
    //         title: "",
    //         detail: `Finish the Wadi tour and watch the sunset experience at Hajalah.`,
    //       },
    //       {
    //         time: "18:00 AM",
    //         title: "",
    //         detail: `Drive back to Tabuk.`,
    //       },
    //       {
    //         time: "20:00 AM",
    //         title: "",
    //         detail: `Arrive in Tabuk. `,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `	Drop off at the hotel and grab your dinner.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Overnight stay at the reserved hotel. `,
    //       },
    //     ],
    //   },
    //   {
    //     id: 3,
    //     name: "Hesma/Bajdah Tour.",
    //     description: [
    //       {
    //         time: "08:00 PM",
    //         title: "	Wake up & have your breakfast at the hotel.",
    //         detail: `Assemble at hotel lobby & picked up from hotel by our guide/driver.`,
    //       },
    //       {
    //         time: "08:30 PM",
    //         title: "	",
    //         detail: `Proceed to Hesma /Bajdah desert by 4WD vehicles (Accompanied by a guide/driver).`,
    //       },
    //       {
    //         time: "09:30 PM",
    //         title: "	",
    //         detail: `Arrive on site.`,
    //       },

    //       {
    //         time: "",
    //         title: "	",
    //         detail: `	Visit the famous natural arch mount and get close to taking the pictures. `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `Time to go on a safari experience for dashing the dunes in the desert by 4x4. `,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "	",
    //         detail: `Time for lunch. (Picnic lunch will serve en site).
            
    //         `,
    //       },
    //       {
    //         time: "",
    //         title: "	Visit the Neom caves. (Accompanied by the guide)",
    //         detail: `
            
    //         `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `	Free time for pictures & Enjoys Tea/ Fresh Arabic coffee with dates & snacks.
            
    //         `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `After going on an adventurous ride through the desert exploring the gold sand landscape. Enjoy a magical sunset overlooking the glowing dunes in gold and capture some sunset moments with your lens to cherish the memory forever.
    //         Time to get ready for departure to Tabuk.
            
    //         `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: ` Time to get ready for departure to Tabuk.
            
    //         `,
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "	",
    //         detail: `Drive back to Tabuk.
    //         `,
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "	",
    //         detail: `Arrive in Tabuk.
    //         `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `Drop off at the airport or hotel.
    //           `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `Farewell from Airport. 
    //           `,
    //       },
    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //           `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //           `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../tabuk/PEHALGAAM/01.jpg") },
      { id: 2, imageUrl: require("../tabuk/PEHALGAAM/02.jpg") },
      { id: 3, imageUrl: require("../tabuk/PEHALGAAM/03.jpg") },
      { id: 4, imageUrl: require("../tabuk/PEHALGAAM/04.jpg") },
      { id: 5, imageUrl: require("../tabuk/PEHALGAAM/05.jpg") },
      { id: 6, imageUrl: require("../tabuk/PEHALGAAM/06.jpg") },
      { id: 7, imageUrl: require("../tabuk/PEHALGAAM/07.png") },
      { id: 8, imageUrl: require("../tabuk/PEHALGAAM/09.jpeg") },
      { id: 9, imageUrl: require("../tabuk/PEHALGAAM/10.jpg") },
      
    ],
    tourDetailsInfo: {
      duration: "3",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: 'discover-sonmarg-winter-package',
    name: "Discover SONMARG Winter Package",
    // Duration: "3 days and 2 Nights",
    imageUrl: require("../tabuk/sonmarg.jpg"),
    description: `
    Discover Sonmarg's unspoiled splendour by booking our "Discover Sonmarg Winter Package." The stunning winter wonderland of Sonmarg, often known as the "Meadow of Gold," is situated in the state of Jammu & Kashmir. This special package is made to allow you to enjoy Sonmarg's serene beauty and spirit of adventure in the dead of winter. Take in a scene that is covered in fluffy, powdery snow and provides both exciting outdoor activities and a tranquil haven. For those looking for a combination of action and relaxation, Sonmarg in the winter is a great place to go sledding across icy fields or snowboarding down its mild slopes. Explore the tranquil alpine landscape, take in the icy splendour of the Thajiwas Glacier, and experience the kind, inviting culture of Kashmir.`,
    whatToExpect: `
    Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
    

      "Thajiwas Glacier Visit: Experience the awe-inspiring beauty of the Thajiwas Glacier, a stunning natural wonder covered in snow and ice.",
      "Snowboarding and Skiing: Enjoy the thrill of snowboarding and skiing on the gentle slopes of Sonmarg, perfect for both beginners and experienced enthusiasts.",
      "Sled Rides: Glide through the snowy landscapes on traditional sleds, a fun and unique way to explore the winter beauty of Sonmarg.",
      "Frozen Lake Exploration: Discover the frozen alpine lakes around Sonmarg, offering serene beauty and tranquil moments.",
      "Winter Trekking: Embark on guided treks through snow-covered forests and meadows, experiencing the untouched beauty of the region.",
      
      "Cultural Experiences: Immerse yourself in the local culture with visits to traditional Kashmiri villages and interactions with the warm-hearted locals.",
      "Photography Tours: Capture the enchanting winter landscapes, from frozen waterfalls to snow-laden pine forests, perfect for photography enthusiasts.",
      "Kashmiri Cuisine Tasting: Warm up with delicious Kashmiri dishes, known for their rich flavors and aromatic spices.",
      "Snow Picnics: Enjoy picnics in picturesque locations, surrounded by the stunning winter scenery of Sonmarg.",
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [   
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Alula",
    //     description: [
    //       {
    //         time: "8:30 AM",
    //         title: "",
    //         detail:
    //           "Proceed to winter park and depart to Hegra explore Hegra (Madyan Saleh).",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: ".",
    //         detail: `Start your tour by visiting Saudi Arabia’s first UNESCO world heritage site and the largest preserved site of the Nabatean civilization south of Petra. Home to more than 110 tombs carved from giant rock formations, this ancient site is open for visitors to explore and learn about the culture, traditions, and history of its people. Plus, you’ll see Jabal Ithlib and the Tomb of Lihyan son of Kuza. Hegra Get more from the archaeological site with a guide who knows the history. No need to worry about your entrance fee to Hegra..
    //           `,
    //       },
    //       {
    //         time: "12:00 PM",
    //         title: "",
    //         detail: `finish Hegra tour and back to winter park and explore the winter park and its market.`,
    //       },
    //       {
    //         time: "13:00 PM",
    //         title: "",
    //         detail: `Proceed to one local farm & have your lunch. (Farm or in a restaurant).
    //         Take a tour to one Alula oasis & Explore the Resonant Shell (A physical and spiritual experience within the Alula Oasis) and next you will drive to visit the Alula old town and its market & explore Saudi's Ancient village "AlUla old town" and experience the understanding, continuity, and evolution of the historic routes for trade and pilgrimage, travelers, and permanent settlers congregated through the ages. AlUla Old Town offers researchers unique oral histories and living memory that the area’s other heritage sites cannot. With 900 houses, 500 shops, and 5 town squares, you can explore a maze of remnants of original stone and mud brick buildings and enjoy views of an AlUla Castle dating back to the 10th century, afterwards head to Aljadidah. (Aljadidah is home to restaurants, shops and more. Al Jadidah is the place where the world’s largest hand painted carpet lines the road for visitors. 
    //         `,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: ` Proceed to Elephant rock and Visit the famous Elephant (Jabal Al Fil) rock, this spectacular rock formation resembles an elephant with its trunk touching the ground, , is one of Aula's geomorphological wonders and became one of AlUla iconic landmarks. Elephant Rock, also known as Jabal AlFil, one of Aula's many geological marvels. As the sun sets, colors dance in the sky and this epic elephant turns a magnificent shade of red.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: ` Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.`,
    //       },
    //       {
    //         time: "19:00 PM",
    //         title: "",
    //         detail: `Get back to your vehicles and drive to the Accommodation. `,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: ` Drop off at your stay and grab your dinner at the hotel restaurant.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: ` Overnight stay at the reserved hotel. `,
    //       },
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: Alula",
    //     description: [
    //       {
    //         time: "08:30 AM",
    //         title: " Morning breakfast at the hotel restaurant.",
    //         detail:
    //           "Assemble at the hotel lobby & Proceed to winter park and depart to Dadan/Ikmah.",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: "Departs to Dadan/Ikmah experience.",
    //         detail: `Start visiting Dadan, the ancient Kingdom of the Lihyanite and Dadanite civilizations. Next, a bus service will take you on a 10-minute drive to Jabal Ikmah situated in a stunning desert canyon, this open-air library has the most impressive collection of inscriptions of the Islamic era dating back to 24AH (644 CE). Thousands of pre-Arabic inscriptions across numerous sites make AlUla an important location in studying the Arabic language and no such site is more significant than Jabal Ikmah, home to the highest concentration of and most varied inscriptions in AlUla. In a beautiful desert landscape warmed by the sun, the largest “open Start your journey through time at Dadan, the ancient Kingdom of the Lihyanite and Dadanite civilizations. Next, a bus service will take you on a 10-minute drive to Jabal Ikmah situated in a stunning desert canyon, this open-air library has the most impressive collection of inscriptions of the Islamic era dating back to 24AH (644 CE). Thousands of pre-Arabic inscriptions across numerous sites make AlUla an important location in studying the Arabic language and no such site is more significant than Jabal Ikmah, home to the highest concentration of and most varied inscriptions in AlUla. In a beautiful desert landscape warmed by the sun, the largest “open library” in Saudi Arabia sits tucked away in a remote canyon valley.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Drive back to winter park after finishing the experience.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Take a tour to Alwaha farm followed by lunch .`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Take a day tour to the famous elephant rock mount and where you will stop for a photo break .Your next step will be to a famous view point namely Harrat Uwayrid .`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Visit to see the Harrat Uwayrid and check out the Octo café and the amazing view over the Dadan, Alula Old town, Alula old fort and the Alula Oasis from the top of the highest mountain. Which is full of many lookout points that offer you wonderful views of the beautiful landscapes. Where we can enjoy watching the stony terrain of black lava from a height.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Enjoy a magical sunset at Harrat view point and capture some sunset moments with your lens to cherish the memory forever.`,
    //       },
    //       {
    //         time: "19:00 AM",
    //         title:
    //           "Get back to your vehicles and drive to the Accommodation. .",
    //         detail: `Drop off at your stay and grab your dinner at the hotel restaurant.Overnight stay at the reserved hotel. `,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Overnight stay at the reserved hotel. `,
    //       },
    //     ],
    //   },
    //   {
    //     id: 3,
    //     name: "Day 3 :Alula.",
    //     description: [
    //       {
    //         time: "",
    //         title: "Morning breakfast at the hotel restaurant.",
    //         detail: ``,
    //       },
    //       {
    //         time: "08:00 AM",
    //         title: "",
    //         detail: `Picked up from the hotel and proceed to one of Alula’s deserts. Take a morning desert safari tour & explore the Alula deserts in the morning and possibly see the camel that surrounds the deserts of AlUla.
    //      `,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Visit to see the famous Alula old Arch namely Rainbow Rock arch Mount followed by picnic lunch in the desert.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: ` Check out old engraving sites and some rock art carving sites.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: ` Drive back to the hotel and pick up your bags.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Head to the airport and departure from Alula Airport.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Fare well from Alula Airport by the end of the tour.`,
    //       },
    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //           `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //           `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../allula/SONMARG/01.jpg") },
      { id: 2, imageUrl: require("../allula/SONMARG/02.jpg") },
      { id: 3, imageUrl: require("../allula/SONMARG/03.jpg") },
      { id: 4, imageUrl: require("../allula/SONMARG/04.jpg") },
      { id: 5, imageUrl: require("../allula/SONMARG/05.jpg") },
      { id: 6, imageUrl: require("../allula/SONMARG/06.jpg") },
      { id: 7, imageUrl: require("../allula/SONMARG/07.webp") },
      { id: 8, imageUrl: require("../allula/SONMARG/08.jpg") },
      { id: 9, imageUrl: require("../allula/SONMARG/09.jpeg") },
      { id: 10, imageUrl: require("../allula/SONMARG/10.jpg") },
     
    ],

    tourDetailsInfo: {
      duration: "3",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id:  'full-week-Tour-with-picnic-lunch-from-ladakh',
    name: " Full Week Tour To LADAKH",
    // Duration: "1 week",
    imageUrl: require("../tabuk/Ladakh-Tour-1.jpg"),
    description: `
    Leh Palace offers magnificent vistas as you begin your exploration of Ladakh. Travel via Chang La Pass to Pangong Lake, which is encircled by snow-capped hills. Explore the sand dunes, double-humped camels, and Diskit Monastery in Nubra Valley. Experience the spiritual legacy of Ladakh at the monasteries of Thiksey, Hemis, and Shey. Take in the striking beauty of Ladakh's landscapes and lively festivities.`,
    whatToExpect: `
    Embark on an exciting Ladakh adventure with a knowledgeable guide picking you up from the airport. Start your journey at the historic Leh Palace, offering panoramic views of the mountains. Visit cultural gems like the Diskit Monastery, experience the surreal Magnetic Hill, and explore serene lakes like Pangong and Tso Moriri. Conclude your trip with a stroll through Leh's vibrant markets, leaving you with unforgettable memories of Ladakh's natural wonders and rich cultural heritage.`,
    highlights: [
      "Witness the awe-inspiring natural wonders of Ladakh, Kashmir, in places like the enchanting Nubra Valley.",

      "Visit the ancient legacy, the historic Leh Palace overlooking the stunning landscapes.",
      
      "Learn about the great canyons and valleys of Ladakh, such as the captivating Zanskar Gorge.",
      
      "Check out the famous window known as the 'Ladakh Vista'.",
      
      "Enjoy driving deeper into the breathtaking canyons of Ladakh by 4x4 open cars.",
      
      "Discover the ancient engravings on the walls of the centuries-old monasteries, like Hemis Monastery.",
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [   
      "Trusted Agency in Association With budget kashmir.",
      "Create everlasting memories.",
      "Enjoy more value at the best price.",
      "Get served by certified experts.",
      "Guided tours.",
      "Convenient Pickup & Drop off.",],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "",
    //     description: [
    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //         `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //         `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../dissa/LEH LADAKH/01.jpg") },
      { id: 2, imageUrl: require("../dissa/LEH LADAKH/02.jpg") },
      { id: 3, imageUrl: require("../dissa/LEH LADAKH/03.jpg") },
      { id: 4, imageUrl: require("../dissa/LEH LADAKH/04.jpg") },
      { id: 5, imageUrl: require("../dissa/LEH LADAKH/05.jpg") },
      { id: 6, imageUrl: require("../dissa/LEH LADAKH/06.jpg") },
      { id: 7, imageUrl: require("../dissa/LEH LADAKH/07.jpg") },
      { id: 8, imageUrl: require("../dissa/LEH LADAKH/08.jpg") },
      { id: 9, imageUrl: require("../dissa/LEH LADAKH/09.jpg") },
      { id: 10, imageUrl: require("../dissa/LEH LADAKH/10.jpg") },
    
    ],
    tourDetailsInfo: {
      duration: "1",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: 'full-day-tour-to-peer-ki-gali-with-picnic-lunch',
    name: " Full day  Tour TO PEER KI GALI",
    // Duration: "1 Day",
    imageUrl: require("../tabuk/peer ki gali.jpg"),
    description: `With our "Full-Day Tour to Peer Ki Gali with Picnic Lunch," take a breath-taking trip into the unspoiled splendour of Kashmir. The breathtaking Peer Ki Gali Pass on the ancient Mughal Road provides expansive vistas of the imposing mountains and vast meadows that characterise the area's natural beauty. This private tour is intended to fully immerse you in the serenity and stunning scenery of one of Kashmir's most picturesque and high passes.
   
    As you traverse this ancient route, you'll be enveloped by the serene landscapes that have captivated travelers for centuries. The tour includes stops at key viewpoints, where you can soak in the vast, untouched wilderness of the area. Your journey will be complemented by a delightful picnic lunch, set against the backdrop of the picturesque valleys and snow-capped peaks, offering a moment of relaxation and indulgence in nature's lap.`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
     `,
    highlights: [
   "Scenic Drive on Mughal Road: Journey through the historic Mughal Road, enjoying breathtaking views of mountains and valleys along the way.",
   "Panoramic Views at Peer Ki Gali: Experience unparalleled vistas from Peer Ki Gali, the highest point on the Mughal Road, offering sweeping views of the surrounding landscapes.",
   "Guided Historical Insights: Learn about the rich history and cultural significance of Peer Ki Gali and the Mughal Road from knowledgeable guides.",
   "Picnic Lunch Amidst Nature: Enjoy a carefully curated picnic lunch in one of the picturesque meadows, surrounded by the serene beauty of the Kashmiri countryside.",
   "Photography Opportunities: Capture stunning photographs of the pristine landscapes, vibrant meadows, and the unique flora and fauna of the region.",
   "Nature Walks and Exploration: Take leisurely walks through the lush meadows and explore the natural beauty of the area at your own pace.",
   "Interaction with Local Nomads: Get a chance to meet and interact with the local nomadic communities, gaining insight into their traditional way of life.",
   "Bird Watching: Peer Ki Gali is a haven for bird watchers, offering the opportunity to spot a variety of high-altitude and migratory birds in their natural habitat.",
   "Relaxation and Serenity:Find peace and tranquility amidst the natural splendor of Peer Ki Gali, away from the hustle and bustle of city life.",
   "Cultural Experience: Delve into the local culture and traditions, enriching your travel experience with authentic Kashmiri hospitality.",
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "",
    //     description: [
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `First stop: Jabel al Lawz/Jabal Maqla. Jabal Al-Lawz, known in Arabic as the "The Almond Mountain '', is the most majestic and the highest mountain in Saudi Arabia. You may find thousands of years old inscriptions, meet locals tending their herds, and see the beautiful but reclusive Arabian ibex if you are fortunate. Jabal Al-Lawz receives winter snowfalls, turning the raw and majestic landscape into a spectacular winter wonderland. In winter, head for Jabal Al-Lawz and enjoy the cooler breezes that wind through the peaks of the surrounding ranges and if you time it just right, enjoy a day of snow and winter fun.
    //       `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: ` Stop at Elijah's Cave, located at the second highest peak of Jabal Maqla. 
    //       `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `Go to the Golden Calf Petroglyph. When the people saw that Moses was so long to come down from the mountain, the Israelites made Aaron an image of a golden calf. Surprisingly, all the above-mentioned traces are found close to Mt. Jabal Maqla. The huge altar, where supposedly the image of the golden calf was placed, cannot be missed. And on the altar's stone are numerous petroglyphs, including the one depicting the people holding a festival and dancing.
    //       `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `  Make a stop for lunch at 13:00. Then head to the Graveyard.
    //       `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `See the 12-stone altars in front of Jabal Lawz Mountain and proceed to Split Rock Mountain. Examine the Split Rock. The giant rock split is located in the Northwest of Horeb at Jabal Maqla, where Moses struck the rock, and God miraculously provided water from within.
    //       `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `  Drive back to Tabuk and Drop off at your stay or airport by the end of the tour.
    //       `,
    //       },
    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //       `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //       `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../jabal/PEER KI GALI/01.jpg") },
      { id: 2, imageUrl: require("../jabal/PEER KI GALI/02.jpg") },
      { id: 3, imageUrl: require("../jabal/PEER KI GALI/03.jpg") },
      { id: 4, imageUrl: require("../jabal/PEER KI GALI/04.webp") },
      { id: 5, imageUrl: require("../jabal/PEER KI GALI/05.webp") },
      { id: 6, imageUrl: require("../jabal/PEER KI GALI/06.webp") },
      { id: 7, imageUrl: require("../jabal/PEER KI GALI/07.jpg") },
     
    ],
    tourDetailsInfo: {
      duration: "1",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: 'full-day-tour-with-picnic-lunch-from-parimehal',
    name: "The Ultimate Tour To PARIMAHAL",
    // Duration: "1 Day",
    imageUrl: require("../tabuk/parimehal.png"),
    description: `Discover Srinagar's Pari Mahal, a historic site featuring terraced gardens that provide views of Dal Lake. Explore its architectural beauty and cultural legends. In the calm atmosphere, pay your respects while admiring the lake and mountains. A peaceful getaway, Pari Mahal creates a singular experience by fusing history, culture, and scenic beauty. Take in all that this charming place has to offer.
    `,
    whatToExpect: ` 
    Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
     
   "Explore the enchanting Pari Mahal with its terraced gardens and breathtaking views.",
   "Stroll through the mesmerizing beauty of Pari Mahal, a historical gem in Srinagar.",
   "Admire the architectural marvels and immerse yourself in the cultural tales it holds.",
   "Pay your respects in the serene ambiance, creating a unique experience.",
    "Conclude your day with a return to Srinagar, cherishing the memories of Pari Mahal.",
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "",

    //     description: [
    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //   `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //   `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../neom/parimehal/01.webp") },
      { id: 2, imageUrl: require("../neom/parimehal/02.jpg") },
      { id: 3, imageUrl: require("../neom/parimehal/03.jpg") },
      { id: 4, imageUrl: require("../neom/parimehal/04.jpg") },
      { id: 5, imageUrl: require("../neom/parimehal/05.jpg") },
      { id: 6, imageUrl: require("../neom/parimehal/06.jpg") },
      { id: 7, imageUrl: require("../neom/parimehal/07.jpg") },
      { id: 8, imageUrl: require("../neom/parimehal/08.jpg") },
      { id: 9, imageUrl: require("../neom/parimehal/09.png") },
      { id: 10, imageUrl: require("../neom/parimehal/10.jpg") },
     
    ],
    tourDetailsInfo: {
      duration: "3",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: 'the-ultimate-nishat-garden-experience',
    name: "The Ultimate NISHAT Garden Experience",
    // Duration: "1 Day",
    imageUrl: require("../tabuk/nishat.jpg"),
    description: `Take a quick tour to Nishat Bagh, often known as the "Garden of Joy," and discover its splendour. This Mughal garden, with its terraced lawns, colourful flower beds, and flowing water systems, is a masterwork of landscape architecture, set against the breathtaking background of Dal Lake and the Zabarwan Mountains. Our tour provides soothing views and a tranquil escape into the embrace of nature, while also providing an insight into the garden's rich history and architectural splendour. Come along for a quick but enlightening visit to one of Srinagar's most beloved sites.`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
     
      "Terraced Gardens: Explore the twelve terraces of Nishat Bagh, each offering unique vistas and symbolizing the Zodiac signs.",
      "Majestic Views: Enjoy panoramic views of Dal Lake and the Zabarwan Mountains, providing a perfect backdrop for photography.",
      "Mughal Architecture: Admire the intricate Mughal architectural designs, including elegant pavilions and water channels.",
      "Floral Splendor: Witness the vibrant array of flowers and meticulously maintained gardens, showcasing seasonal blooms.",
      "Tranquil Water Features: Relax by the serene watercourses and fountains that add to the garden's peaceful ambiance.",
      "Historical Insights: Gain insights into the garden's rich history and its significance in Mughal culture from knowledgeable guides.",
      "Photogenic Landscapes: Capture the beauty of Nishat Bagh's landscapes, making it a haven for photography enthusiasts.",
      "Peaceful Retreat: Find a tranquil escape in the garden's lush green spaces, ideal for contemplation and relaxation.",
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "",

    //     description: [
    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //   `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //   `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../dune/NISHAT/01.jpg") },
      { id: 2, imageUrl: require("../dune/NISHAT/02.jpg") },
      { id: 3, imageUrl: require("../dune/NISHAT/03.jpg") },
      { id: 4, imageUrl: require("../dune/NISHAT/04.jpg") },
      { id: 5, imageUrl: require("../dune/NISHAT/05.jpg") },
      { id: 6, imageUrl: require("../dune/NISHAT/06.jpg") },
      { id: 7, imageUrl: require("../dune/NISHAT/07.jpg") },
      { id: 8, imageUrl: require("../dune/NISHAT/08.jpg") },
      { id: 9, imageUrl: require("../dune/NISHAT/10.webp") },
      // { id: 10, imageUrl: require("../dune/9.jpg") },
     
    ],
    tourDetailsInfo: {
      duration: "1",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: 'hike-from-harwan-with-picnic-lunch',
    name: "Hike From HARWAN  ",
    // Duration: "1 Day",
    imageUrl: require("../tabuk/harwan.jpg"),
    description: `
    Situated 19 miles from Srinagar in Harwan Village, Harwan Garden is a well-liked and remarkable tourist attraction. Renowned for its unspoiled splendour, the garden showcases a canal surrounded by Chinar trees and flourishing flowerbeds, which emerge from a lake situated at its rear. Large green lawns set against a backdrop of snow make this garden charming and a great place for nature hikes and picnics. In addition to being the entrance to the Dachi Gam Wildlife Sanctuary, Harwan Garden is also easily reached by taxi in thirty minutes.`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
     `,
    highlights: [
     
      "Scenic Beauty: Enjoy the picturesque setting of Harwan Garden, surrounded by mountains and lush greenery, providing a perfect backdrop for relaxation and photography.",
      "Spacious Lawns: Stroll through expansive, well-manicured lawns ideal for leisurely walks and picnics with family and friends.",
      "Water Channels: Marvel at the intricate network of water channels and fountains that add to the serene ambiance of the garden.",
      "Floral Displays: Witness a vibrant array of flowers and ornamental plants that bloom in different seasons, adding color and fragrance to the environment.",
      
      "Family-Friendly: The garden's calm and safe environment makes it an ideal destination for families looking to spend quality time in nature.",
      "Photography Opportunities: Capture stunning shots of the garden's landscapes, water features, and floral beauty, making it a favorite spot for photography enthusiasts.",
      "Accessibility: Easily accessible from Srinagar, making it a convenient excursion for both locals and tourists seeking a tranquil retreat.",
      
      
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Morning Trial Experience. ",
    //     description: [
    //       {
    //         time: "8:00 AM",
    //         title: "",
    //         detail:
    //           "Assemble at the gathering point & picked up by our guide/driver.",
    //       },
    //       {
    //         time: "08:30 AM",
    //         title: "",
    //         detail: `Drive to the location.`,
    //       },
    //       {
    //         time: "9:30 AM",
    //         title: "",
    //         detail: "arrive at the location.",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: "",
    //         detail: `Preparation for Hiking.`,
    //       },
    //       {
    //         time: "10:15 PM",
    //         title: "",
    //         detail: "start Hiking accompanied by the hiking guide.",
    //       },
    //       {
    //         time: "12:00 PM",
    //         title: "",
    //         detail: `Finish hiking and take a rest.(Refreshments serve in site)`,
    //       },
    //       {
    //         time: "13:00 PM",
    //         title: "",
    //         detail: `Take a safari tour around the desert.`,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "",
    //         detail: `Grab your lunch.( Serve at Campsite)`,
    //       },
    //       {
    //         time: "15:00 PM",
    //         title: "",
    //         detail: `Take a tour to the Neom caves visit`,
    //       },
    //       {
    //         time: "17:00 PM",
    //         title: "",
    //         detail: `Finish Neom caves visit and get back to your vehicles.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: `Departure to Tabuk.`,
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "",
    //         detail: `Arrive in Tabuk.`,
    //       },
    //       {
    //         time: "",
    //         title: "Afternoon trial experience.",
    //         detail: ``,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "",
    //         detail: `Assemble at the gathering point & picked up by our guide/driver.`,
    //       },
    //       {
    //         time: "14:30",
    //         title: "",
    //         detail: `Drive to the location.`,
    //       },
    //       {
    //         time: "15:30",
    //         title: "",
    //         detail: `Arrive at the location`,
    //       },
    //       {
    //         time: "16:00 PM",
    //         title: "",
    //         detail: `Preparation for Hike.`,
    //       },
    //       {
    //         time: "16:10:",
    //         title: "",
    //         detail: `start Hiking accompanied by the hiking guide.`,
    //       },
    //       {
    //         time: "17:00 PM",
    //         title: "",
    //         detail: `Finish hiking and Finish hiking and take a rest.(Refreshments serve in site)`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Relax and enjoy Arabic coffee, Tea with snacks.`,
    //       },
    //       {
    //         time: "",
    //         title:
    //           "	Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.",
    //         detail: ``,
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "",
    //         detail: `Head to campsite & Have dinner at the campsite.`,
    //       },
    //       {
    //         time: "21:00 PM",
    //         title: "	",
    //         detail: `Drive back to Tabuk.
    //         `,
    //       },
    //       {
    //         time: "22:00 PM",
    //         title: "	",
    //         detail: `Arrive in Tabuk.
    //         `,
    //       },
    //       {
    //         time: "22:00 PM",
    //         title: "	",
    //         detail: `Farewell from Tabuk
    //         `,
    //       },
    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //   `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //         `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../hike/harwan/01.jpg") },
      { id: 2, imageUrl: require("../hike/harwan/02.webp") },
      { id: 3, imageUrl: require("../hike/harwan/03.jpg") },
      { id: 4, imageUrl: require("../hike/harwan/04.jpg") },
      { id: 5, imageUrl: require("../hike/harwan/05.jpg") },
      { id: 6, imageUrl: require("../hike/harwan/06.jpg") },
      { id: 7, imageUrl: require("../hike/harwan/07.jpg") },
      { id: 8, imageUrl: require("../hike/harwan/08.png") },
      
    ],
    tourDetailsInfo: {
      duration: "1",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: 'the-unforgetable-shalimar-garden-experience',
    name: "The Unforgetable SHALIMAR Garden Experience",
    // Duration: "1 days   ",
    imageUrl: require("../tabuk/shaalimar.jpg"),
    description: `A waterway connects Shalimar Bagh, a Mughal garden in Srinagar, Jammu & Kashmir, India, to Dal Lake's northeast. It is also referred to as Faiz Baksh, Farah Baksh, and Shalimar Gardens. Nishat Bagh, also known as "The Garden of Delight," is another well-known waterfront garden in the area. Jahangir, the Mughal Emperor, constructed the Bagh in 1619. The Bagh is regarded as the pinnacle of Mughal gardening. Currently a public park, it is also known as the "Crown of Srinagar."".`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
      " Begin your visit by exploring the terraced levels and pathways of Shalimar Garden.",
      "Take your time to appreciate the meticulously designed layout and symmetrical patterns of the garden.",
      "Delight in the sight and sound of the fountains and water channels that are characteristic of Mughal gardens.",
      "Capture the reflection of the surrounding greenery in the garden's reflective pools.",
      "Visit the Shalimar Bagh Pavilion, a central structure that offers panoramic views of the entire garden.",
      " Take a moment to absorb the historical and architectural significance of this iconic pavilion.",
      "  Wander through the lush greenery and vibrant flower beds that adorn the garden.",
      "Identify and appreciate the various plant species carefully curated within Shalimar Garden.",
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Tabuk",
    //     description: [
    //       {
    //         time: "16:00 PM",
    //         title: "",
    //         detail:
    //           "Assemble at the gathering point & picked up by our guide/driver.",
    //       },
    //       {
    //         time: "16:30 PM",
    //         title: "",
    //         detail: `Drive to the campsite.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: "Arrive at the Campsite.",
    //       },
    //       {
    //         time: "19:00 PM",
    //         title: "",
    //         detail: `•	Relax at the campsite followed by Arabic coffee, tea with snacks. `,
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "",
    //         detail:
    //           "Have dinner at the campsite around the campfire. (BBQ dinner with grills).",
    //       },
    //       {
    //         time: "21:00",
    //         title: "",
    //         detail: `overnight sleep at the campsite. (Tents & sleeping bags will be provided).`,
    //       },
         
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: Tabuk",
    //     description: [
    //       {
    //         time: "8: 30 AM",
    //         title: "",
    //         detail: "wake up and have your breakfast at the campsite.",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: "",
    //         detail: `Take a morning desert safari tour & explore the Tabuk deserts in the morning and possibly see the camel that surrounds the deserts of Tabuk.`,
    //       },
    //       {
    //         time: "13:00 PM",
    //         title: "",
    //         detail: `Lunch Time.`,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "",
    //         detail: `Take a tour to the Neom caves and check out the old inscriptions and rock art formation.(accompanied by the guide).`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Finish Neom caves visit and get back to your vehicles.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `•	Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: `Departure to Tabuk.`,
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "",
    //         detail: `Arrive in Tabuk.`,
    //       },

    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //         `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //         `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../camp/shalimar/01.jpg") },
      { id: 2, imageUrl: require("../camp/shalimar/02.jpg") },
      { id: 3, imageUrl: require("../camp/shalimar/03.jpg") },
      { id: 4, imageUrl: require("../camp/shalimar/04.jpg") },
      { id: 5, imageUrl: require("../camp/shalimar/05.jpg") },
      { id: 6, imageUrl: require("../camp/shalimar/06.jpeg") },
      { id: 7, imageUrl: require("../camp/shalimar/06.jpg") },
      { id: 8, imageUrl: require("../camp/shalimar/07.jpg") },
      { id: 9, imageUrl: require("../camp/shalimar/09.jpg") },
      { id: 10, imageUrl: require("../camp/shalimar/10.jpg") },
    
    ],
    tourDetailsInfo: {
      duration: "2",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: 'the-ultimate-dal-lake-experience',
    name: "The Ultimate Dal Lake Experience",
    // Duration: "1 days   ",
    imageUrl: require("../tabuk/dallake-view.webp"),
    description: `Dal Lake, often referred to as the jewel of Srinagar, is a picturesque water body that epitomizes the beauty and tranquility of Kashmir. Surrounded by majestic mountains and lush greenery, this iconic lake is dotted with vibrant houseboats, known as "Shikaras," and floating gardens, creating a mesmerizing scene. Visitors can embark on leisurely Shikara rides, explore the bustling floating markets, or simply soak in the serenity of the surroundings. Dal Lake is not just a scenic attraction but also a symbol of Kashmir's rich culture and heritage, drawing travelers from far and wide to experience its timeless allure."`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
      " Iconic water body located in Srinagar, Kashmir.",
      "Surrounded by majestic mountains and lush greenery.",
      "Dotted with vibrant houseboats and floating gardens.",
      "Offers leisurely Shikara rides and scenic boat tours.",
      "A symbol of Kashmir's rich culture and heritage.",
     
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Tabuk",
    //     description: [
    //       {
    //         time: "16:00 PM",
    //         title: "",
    //         detail:
    //           "Assemble at the gathering point & picked up by our guide/driver.",
    //       },
    //       {
    //         time: "16:30 PM",
    //         title: "",
    //         detail: `Drive to the campsite.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: "Arrive at the Campsite.",
    //       },
    //       {
    //         time: "19:00 PM",
    //         title: "",
    //         detail: `•	Relax at the campsite followed by Arabic coffee, tea with snacks. `,
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "",
    //         detail:
    //           "Have dinner at the campsite around the campfire. (BBQ dinner with grills).",
    //       },
    //       {
    //         time: "21:00",
    //         title: "",
    //         detail: `overnight sleep at the campsite. (Tents & sleeping bags will be provided).`,
    //       },
         
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: Tabuk",
    //     description: [
    //       {
    //         time: "8: 30 AM",
    //         title: "",
    //         detail: "wake up and have your breakfast at the campsite.",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: "",
    //         detail: `Take a morning desert safari tour & explore the Tabuk deserts in the morning and possibly see the camel that surrounds the deserts of Tabuk.`,
    //       },
    //       {
    //         time: "13:00 PM",
    //         title: "",
    //         detail: `Lunch Time.`,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "",
    //         detail: `Take a tour to the Neom caves and check out the old inscriptions and rock art formation.(accompanied by the guide).`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Finish Neom caves visit and get back to your vehicles.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `•	Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: `Departure to Tabuk.`,
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "",
    //         detail: `Arrive in Tabuk.`,
    //       },

    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //         `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //         `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../camp/dallake/dallake-1.jpg") },
      { id: 2, imageUrl: require("../camp/dallake/dallake-2.jpg") },
      { id: 3, imageUrl: require("../camp/dallake/dallake-3.webp") },
      { id: 4, imageUrl: require("../camp/dallake/dallake-4.jpg") },
     
    
    ],
    tourDetailsInfo: {
      duration: "2",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: 'best-kashmir-honeymoon-packages',
    name: "Best Kashmir Honeymoon Packages",
    // Duration: "1 days   ",
    imageUrl: require("../tabuk/couples-kashmir.webp"),
    description: `
    Honeymoon packages typically include romantic accommodations, special amenities, and curated experiences tailored for newlyweds. They often feature destinations known for their romantic appeal, such as secluded  resorts, charming hill stations, or exotic  getaways. These packages may include activities like candlelit dinners, spa treatments, adventurous excursions, and sightseeing tours to create unforgettable memories for the newlywed couple. Honeymoon packages aim to provide couples with a hassle-free and memorable experience as they celebrate the beginning of their married life together."`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
      " Luxurious accommodations at renowned hotels or houseboats nestled amidst the breathtaking scenery of Kashmir.",
      "Majestic views of snow-capped mountains, serene lakes, and lush valleys, creating a romantic ambiance.",
      "Exclusive experiences like Shikara rides on Dal Lake, trekking in the Himalayas, and picnics in scenic meadows.",
      "Romantic amenities such as candlelit dinners, couples' spa treatments, and private tours to iconic landmarks.",
      "Personalized services to enhance the honeymoon experience, including customized itineraries and attentive hospitality.",





     
     
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Tabuk",
    //     description: [
    //       {
    //         time: "16:00 PM",
    //         title: "",
    //         detail:
    //           "Assemble at the gathering point & picked up by our guide/driver.",
    //       },
    //       {
    //         time: "16:30 PM",
    //         title: "",
    //         detail: `Drive to the campsite.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: "Arrive at the Campsite.",
    //       },
    //       {
    //         time: "19:00 PM",
    //         title: "",
    //         detail: `•	Relax at the campsite followed by Arabic coffee, tea with snacks. `,
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "",
    //         detail:
    //           "Have dinner at the campsite around the campfire. (BBQ dinner with grills).",
    //       },
    //       {
    //         time: "21:00",
    //         title: "",
    //         detail: `overnight sleep at the campsite. (Tents & sleeping bags will be provided).`,
    //       },
         
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: Tabuk",
    //     description: [
    //       {
    //         time: "8: 30 AM",
    //         title: "",
    //         detail: "wake up and have your breakfast at the campsite.",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: "",
    //         detail: `Take a morning desert safari tour & explore the Tabuk deserts in the morning and possibly see the camel that surrounds the deserts of Tabuk.`,
    //       },
    //       {
    //         time: "13:00 PM",
    //         title: "",
    //         detail: `Lunch Time.`,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "",
    //         detail: `Take a tour to the Neom caves and check out the old inscriptions and rock art formation.(accompanied by the guide).`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Finish Neom caves visit and get back to your vehicles.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `•	Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: `Departure to Tabuk.`,
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "",
    //         detail: `Arrive in Tabuk.`,
    //       },

    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //         `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //         `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../camp/honeymoon/honey-1.jpg") },
      { id: 2, imageUrl: require("../camp/honeymoon/honey-2.png") },
      { id: 3, imageUrl: require("../camp/honeymoon/honey-3.jpg") },
      { id: 4, imageUrl: require("../camp/honeymoon/honey-4.png") },
      
    
    ],
    tourDetailsInfo: {
      duration: "2",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: 'the-ultimate-gurez-valley-packages',
    name: "The Ultimate Gurez Valley Packages",
    // Duration: "1 days   ",
    imageUrl: require("../tabuk/gurez-valley.webp"),
    description: `
    
   Gurez Valley in Kashmir is a serene haven nestled amidst the northern Himalayas, boasting breathtaking natural beauty with snow-capped peaks and lush meadows. Diverse communities, including the Shina-speaking people, enrich the valley's cultural tapestry, offering warm hospitality to visitors. Outdoor enthusiasts flock to Gurez for trekking, hiking, and fishing amidst its pristine environment. Steeped in history, the valley's strategic location near ancient trade routes and the Line of Control adds to its allure. Accessible only in the summer months, Gurez Valley promises a tranquil retreat and an unforgettable exploration of Kashmir's hidden gem."`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
      " Gurez Valley, nestled in the northern Himalayas of Kashmir, boasts breathtaking natural beauty with snow-capped peaks, lush meadows, and pristine rivers.",
      "The valley is home to diverse communities, offering visitors a chance to immerse themselves in the unique culture and traditions of the Shina-speaking people.",
      "Outdoor enthusiasts can indulge in trekking, hiking, fishing, and other adventure activities amidst the serene and tranquil environment of Gurez Valley.",
      "With a rich historical heritage dating back centuries, Gurez Valley has served as a strategic location for trade and military purposes due to its proximity to the ancient Silk Route and the Line of Control (LoC) between India and Pakistan.",
      "Accessible only during the summer months due to heavy snowfall in winters, Gurez Valley offers warm hospitality with guesthouses, homestays, and campsites, providing an authentic experience of the local way of life.",





     
     
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Tabuk",
    //     description: [
    //       {
    //         time: "16:00 PM",
    //         title: "",
    //         detail:
    //           "Assemble at the gathering point & picked up by our guide/driver.",
    //       },
    //       {
    //         time: "16:30 PM",
    //         title: "",
    //         detail: `Drive to the campsite.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: "Arrive at the Campsite.",
    //       },
    //       {
    //         time: "19:00 PM",
    //         title: "",
    //         detail: `•	Relax at the campsite followed by Arabic coffee, tea with snacks. `,
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "",
    //         detail:
    //           "Have dinner at the campsite around the campfire. (BBQ dinner with grills).",
    //       },
    //       {
    //         time: "21:00",
    //         title: "",
    //         detail: `overnight sleep at the campsite. (Tents & sleeping bags will be provided).`,
    //       },
         
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: Tabuk",
    //     description: [
    //       {
    //         time: "8: 30 AM",
    //         title: "",
    //         detail: "wake up and have your breakfast at the campsite.",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: "",
    //         detail: `Take a morning desert safari tour & explore the Tabuk deserts in the morning and possibly see the camel that surrounds the deserts of Tabuk.`,
    //       },
    //       {
    //         time: "13:00 PM",
    //         title: "",
    //         detail: `Lunch Time.`,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "",
    //         detail: `Take a tour to the Neom caves and check out the old inscriptions and rock art formation.(accompanied by the guide).`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Finish Neom caves visit and get back to your vehicles.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `•	Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: `Departure to Tabuk.`,
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "",
    //         detail: `Arrive in Tabuk.`,
    //       },

    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //         `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //         `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../camp/gurez/gurez-1.jpg") },
      { id: 2, imageUrl: require("../camp/gurez/gurez-2.webp") },
      { id: 3, imageUrl: require("../camp/gurez/gurez-3.jpg") },
      { id: 4, imageUrl: require("../camp/gurez/gurez-4.webp") },
      
    ],
    tourDetailsInfo: {
      duration: "2",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },

  {
    id: 'best-shri-mata-vaishno-devi-packages',
    name: "Best Shri Mata Vaishno Devi Packages",
    // Duration: "1 days   ",
    imageUrl: require("../tabuk/Vaishno_Devi.webp"),
    description: `
    
    Vaishno Devi, nestled in the Trikuta Mountains of Jammu and Kashmir, is a revered Hindu pilgrimage site attracting millions annually. Devotees embark on a challenging trek or opt for helicopter services to reach the shrine dedicated to Mata Vaishno Devi. Along the journey, they encounter symbolic points like the holy cave and Charan Paduka spring. Culminating in darshan of the divine rocks, the pilgrimage offers a spiritually enriching experience amidst breathtaking mountain scenery."`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
      " Spiritual Destination: Vaishno Devi, situated in the Trikuta Mountains of Jammu and Kashmir, is a revered Hindu pilgrimage site dedicated to Mata Vaishno Devi.",
      "Pilgrimage Trek: Millions of devotees undertake a challenging trek or utilize helicopter services to reach the shrine, traversing rugged terrain and steep pathways.",
      "Symbolic Points: Along the journey, pilgrims encounter significant landmarks such as the holy cave and Charan Paduka spring, each holding symbolic importance in Hindu mythology.",
      "Divine Darshan: The pilgrimage culminates in the darshan of the three divine rocks or 'Pindies,' representing the manifestations of Mata Vaishno Devi, believed to fulfill devotees' wishes.",





     
     
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Tabuk",
    //     description: [
    //       {
    //         time: "16:00 PM",
    //         title: "",
    //         detail:
    //           "Assemble at the gathering point & picked up by our guide/driver.",
    //       },
    //       {
    //         time: "16:30 PM",
    //         title: "",
    //         detail: `Drive to the campsite.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: "Arrive at the Campsite.",
    //       },
    //       {
    //         time: "19:00 PM",
    //         title: "",
    //         detail: `•	Relax at the campsite followed by Arabic coffee, tea with snacks. `,
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "",
    //         detail:
    //           "Have dinner at the campsite around the campfire. (BBQ dinner with grills).",
    //       },
    //       {
    //         time: "21:00",
    //         title: "",
    //         detail: `overnight sleep at the campsite. (Tents & sleeping bags will be provided).`,
    //       },
         
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: Tabuk",
    //     description: [
    //       {
    //         time: "8: 30 AM",
    //         title: "",
    //         detail: "wake up and have your breakfast at the campsite.",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: "",
    //         detail: `Take a morning desert safari tour & explore the Tabuk deserts in the morning and possibly see the camel that surrounds the deserts of Tabuk.`,
    //       },
    //       {
    //         time: "13:00 PM",
    //         title: "",
    //         detail: `Lunch Time.`,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "",
    //         detail: `Take a tour to the Neom caves and check out the old inscriptions and rock art formation.(accompanied by the guide).`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Finish Neom caves visit and get back to your vehicles.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `•	Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: `Departure to Tabuk.`,
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "",
    //         detail: `Arrive in Tabuk.`,
    //       },

    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //         `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //         `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../camp/shiri-vishno-devi/shiri-1.png") },
      { id: 2, imageUrl: require("../camp/shiri-vishno-devi/shiri-2.png") },
      { id: 3, imageUrl: require("../camp/shiri-vishno-devi/shiri-3.jpg") },
      { id: 4, imageUrl: require("../camp/shiri-vishno-devi/shiri-4.jpg") },
    
    
    ],
    tourDetailsInfo: {
      duration: "2",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },

  {
    id: 'best-amarnath-yatra-packages',
    name: "Best Amarnath Yatra Packages",
    // Duration: "1 days   ",
    imageUrl: require("../tabuk/amarnath.jpg"),
    description: `
    
   
The Amarnath Yatra, a revered Hindu pilgrimage to the Amarnath Cave in Jammu and Kashmir's Himalayas, draws devotees seeking blessings from Lord Shiva. Pilgrims navigate rugged mountain terrain, either trekking or using helicopter services. En route, they're mesmerized by the scenic beauty of snow-capped peaks and valleys. At the cave, they encounter the sacred ice lingam, a natural formation believed to embody Lord Shiva. The yatra offers not just a physical journey but a deeply spiritual experience for participants."`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
      " Spiritual Pilgrimage: The Amarnath Yatra is a revered Hindu pilgrimage to the Amarnath Cave in the Himalayas of Jammu and Kashmir, dedicated to Lord Shiva.",
      "Challenging Terrain: Pilgrims navigate rugged mountain paths, facing challenging terrain and weather conditions during the journey.",
      "Natural Beauty: En route, participants are treated to breathtaking views of snow-capped peaks, pristine valleys, and lush landscapes.",
      "Sacred Ice Lingam: At the cave, devotees encounter the natural ice lingam, believed to symbolize Lord Shiva's presence and power.",





     
     
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Tabuk",
    //     description: [
    //       {
    //         time: "16:00 PM",
    //         title: "",
    //         detail:
    //           "Assemble at the gathering point & picked up by our guide/driver.",
    //       },
    //       {
    //         time: "16:30 PM",
    //         title: "",
    //         detail: `Drive to the campsite.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: "Arrive at the Campsite.",
    //       },
    //       {
    //         time: "19:00 PM",
    //         title: "",
    //         detail: `•	Relax at the campsite followed by Arabic coffee, tea with snacks. `,
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "",
    //         detail:
    //           "Have dinner at the campsite around the campfire. (BBQ dinner with grills).",
    //       },
    //       {
    //         time: "21:00",
    //         title: "",
    //         detail: `overnight sleep at the campsite. (Tents & sleeping bags will be provided).`,
    //       },
         
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: Tabuk",
    //     description: [
    //       {
    //         time: "8: 30 AM",
    //         title: "",
    //         detail: "wake up and have your breakfast at the campsite.",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: "",
    //         detail: `Take a morning desert safari tour & explore the Tabuk deserts in the morning and possibly see the camel that surrounds the deserts of Tabuk.`,
    //       },
    //       {
    //         time: "13:00 PM",
    //         title: "",
    //         detail: `Lunch Time.`,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "",
    //         detail: `Take a tour to the Neom caves and check out the old inscriptions and rock art formation.(accompanied by the guide).`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Finish Neom caves visit and get back to your vehicles.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `•	Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: `Departure to Tabuk.`,
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "",
    //         detail: `Arrive in Tabuk.`,
    //       },

    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //         `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //         `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../camp/amarnath/amarnath-1.webp") },
      { id: 2, imageUrl: require("../camp/amarnath/amarnath-2.webp") },
      { id: 3, imageUrl: require("../camp/amarnath/amarnath-3.webp") },
      { id: 4, imageUrl: require("../camp/amarnath/amarnath-4.jpg") },
     
    ],
    tourDetailsInfo: {
      duration: "2",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },

  {
    id: 'best-doodpathri-packages',
    name: "Best Doodhpathri Packages",
    // Duration: "1 days   ",
    imageUrl: require("../tabuk/Doodpathri-3.jpg"),
    description: `
    
   

    Doodhpathri, nestled in the picturesque Budgam district of Jammu and Kashmir, is a pristine valley renowned for its natural beauty and tranquil ambiance. Aptly named 'Valley of Milk,' it boasts lush green meadows, dense forests, and gurgling streams that create a serene and captivating landscape. Visitors can indulge in activities like picnicking, horse riding, and trekking, while immersing themselves in the breathtaking vistas and refreshing air of this idyllic Himalayan retreat."`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
      " Natural Beauty: Doodhpathri, located in the Budgam district of Jammu and Kashmir, is celebrated for its stunning natural landscapes, including lush meadows, dense forests, and flowing streams.",
      "Tranquil Environment: The valley offers a peaceful and serene ambiance, making it an ideal retreat for those seeking relaxation and rejuvenation amidst nature.",
      "Outdoor Activities: Visitors can engage in various recreational activities such as picnicking, horse riding, trekking, and nature walks, allowing them to immerse themselves fully in the valley's beauty.",
      "Accessibility: Doodhpathri is easily accessible by road from Srinagar, making it a convenient day trip or weekend getaway for tourists and locals alike.",


    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Tabuk",
    //     description: [
    //       {
    //         time: "16:00 PM",
    //         title: "",
    //         detail:
    //           "Assemble at the gathering point & picked up by our guide/driver.",
    //       },
    //       {
    //         time: "16:30 PM",
    //         title: "",
    //         detail: `Drive to the campsite.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: "Arrive at the Campsite.",
    //       },
    //       {
    //         time: "19:00 PM",
    //         title: "",
    //         detail: `•	Relax at the campsite followed by Arabic coffee, tea with snacks. `,
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "",
    //         detail:
    //           "Have dinner at the campsite around the campfire. (BBQ dinner with grills).",
    //       },
    //       {
    //         time: "21:00",
    //         title: "",
    //         detail: `overnight sleep at the campsite. (Tents & sleeping bags will be provided).`,
    //       },
         
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: Tabuk",
    //     description: [
    //       {
    //         time: "8: 30 AM",
    //         title: "",
    //         detail: "wake up and have your breakfast at the campsite.",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: "",
    //         detail: `Take a morning desert safari tour & explore the Tabuk deserts in the morning and possibly see the camel that surrounds the deserts of Tabuk.`,
    //       },
    //       {
    //         time: "13:00 PM",
    //         title: "",
    //         detail: `Lunch Time.`,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "",
    //         detail: `Take a tour to the Neom caves and check out the old inscriptions and rock art formation.(accompanied by the guide).`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Finish Neom caves visit and get back to your vehicles.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `•	Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: `Departure to Tabuk.`,
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "",
    //         detail: `Arrive in Tabuk.`,
    //       },

    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //         `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //         `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../camp/doodphatri/doodpathri-1.jpg") },
      { id: 2, imageUrl: require("../camp/doodphatri/doodpathri-2.jpg") },
      { id: 3, imageUrl: require("../camp/doodphatri/doodhpathri-3.jpg") },
      { id: 4, imageUrl: require("../camp/doodphatri/doodphatri-4.jpg") },
      
    
    ],
    tourDetailsInfo: {
      duration: "2",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },


  
];
