import React, { Component } from "react";

class Achievement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        {/* =============== achievement area start =============== */}
        {/* <div className="achievement-area p-80 mt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="section-head pb-30">
                  <h2>Why choosing SJH Tours is a wise decision</h2>
                </div>
              </div>
            </div>
            <div className="row">
            <div
                className="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft animated"
                data-wow-duration="1500ms"
                data-wow-delay="200ms"
              >
                <div className="achievement-card mt-30">
                  <div className="achievement-icon">
                    <i className="flaticon-trust" />
                  </div>
                  <h5 style={{ fontSize: "20px" }}>
                  Leading DMC in Saudi Arabia, known for excellence.
                  </h5>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft animated"
                data-wow-duration="1500ms"
                data-wow-delay="0ms"
              >
                <div className="achievement-card mt-30">
                  <div className="achievement-icon">
                    <i className="flaticon-guide" />
                  </div>
                  <h5 style={{ fontSize: "20px" }}>
                    Sjh tours offers a wide range of services
                    <br></br>
                    <br></br>
                  </h5>
                </div>
              </div>
             
              <div
                className="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft animated"
                data-wow-duration="1500ms"
                data-wow-delay="400ms"
              >
                <div className="achievement-card mt-30">
                  <div className="achievement-icon">
                    <i className="flaticon-experience" />
                  </div>
                  <h5 style={{ fontSize: "20px" }}>
                    6+ year of travel experience
                    <br></br>
                  <br></br>

                  </h5>
                  
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft animated"
                data-wow-duration="1500ms"
                data-wow-delay="600ms"
              >
                <div className="achievement-card mt-30">
                  <div className="achievement-icon">
                    <i className="flaticon-traveller" />
                  </div>
                  <h5 style={{ fontSize: "20px" }}>
                    Our dedicated team of professionals
                    <br></br>
                    <br></br>
                  
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* =============== achievement area end =============== */}
      </>
    );
  }
}

export default Achievement;
