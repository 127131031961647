import React, { Component } from 'react';
import BreadCrumb from './BreadCrumb';
import AboutWrapper from './AboutWrapper';
import GuideWrapper from './GuideWrapper';
import Achievement from './Achievement';
import AboutReview from './AboutReview';
import { Helmet } from 'react-helmet';
// import AboutBlog from "./AboutBlog";

class AboutUs extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Discover our journey & commitment to unique travel experiences</title>
          <meta name='description' content=' Explore the stories of KashTours. Learn about our passion, travel experiences, our team, and our commitment to sustainable and personalized adventures.' />
          <meta name='keywords' content='react, meta tags, seo' />
          <meta name='author' content='Your Name' />
          <meta property='og:title' content=' Discover our journey & commitment to unique travel experiences' />
          <meta title=' Discover our journey & commitment to unique travel experiences'/>
          <meta 
            property='og:description'
            content=' Explore the stories of KashTours. Learn about our passion, travel experiences, our team, and our commitment to sustainable and personalized adventures.'
          />
          <meta property='og:image' content='https://example.com/image.jpg' />
          <meta property='og:url' content='https://example.com/my-page' />
          <meta name='twitter:title' content='My Page Title' />
          <meta
            name='twitter:description'
            content=' Explore the stories of KashTours. Learn about our passion, travel experiences, our team, and our commitment to sustainable and personalized adventures.'
          />
          <meta name='twitter:image' content='https://example.com/image.jpg' />
          <meta name='twitter:card' content='summary_large_image' />
        </Helmet>
        <BreadCrumb />
        <AboutWrapper />
        <GuideWrapper />
        <Achievement />
        <AboutReview />
        {/* <AboutBlog/> */}
      </>
    );
  }
}

export default AboutUs;
